import React, { useState, useEffect } from "react";
import InputPassword from "../Inputs/InputPassword";
import InputNewPassword from "../Inputs/InputNewPassword";
import FormTwoFactor from "./FormTwoFactor";
import {
  actualizarPasswordUser,
  getInfoUser,
  resendCodeTwoFactor,
} from "../../services/api";
import ModalCardPages from "../Modal/ModalCardPages";
import ModalCardMedPages from "../Modal/ModalCardMedPages";
import Loader from "../Loader/Loader";
import { verificarPassword, TIME_ALERT } from "../../services/data";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import InputPasswordConfirm from "../Inputs/InputPasswordConfirm";
const CambioPassword = ({
  onCancel = () => {},
  onActualizar = () => {},
  emailUser = "",
  disabledCancel = false,
}) => {
  const [passActual, setPassActual] = useState("");
  const [passNew, setPassNew] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [banderaPass, setBanderaPass] = useState("blank");
  const [pedirTwoFactor, setPedirTwoFactor] = useState(false);
  const [nombreUser, setNombreUser] = useState("");
  const [codigo, setCodigo] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);

  useEffect(() => {
    obtenerInfoUser();
  }, []);
  const obtenerInfoUser = async () => {
    try {
      setLoading(true);
      let res = await (
        await getInfoUser(sessionStorage.getItem("userEmail"))
      ).json();
      if (res.isSucceed) {
        setNombreUser(res.data?.user?.nombre);
      }
    } catch (error) {
      onActualizar({
        OK: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
        data: error,
      });
    } finally {
      setLoading(false);
    }
  };

  const validarPassword = () => {
    return (
      passActual &&
      passNew &&
      (banderaPass === "strong" || banderaPass === "green") &&
      passConfirm
    );
  };
  const validacionPasswordPrevioEnvio = () => {
    if (passActual === passNew) {
      onActualizar({
        OK: false,
        message: "Las contraseñas no pueden ser iguales",
        data: "Iguales Password",
        type: "password",
      });
      return false;
    } else if (passConfirm !== passNew) {
      onActualizar({
        OK: false,
        message: "La contraseña de confirmación no coincide",
        data: "Password No Coincide",
        type: "password",
      });
      return false;
    } else {
      return true;
    }
  };
  const solicitarCodigoTwoFactor = async () => {
    try {
      if (!navigator.onLine) {
        onActualizar({
          OK: false,
          message:
            "No hay conexión a Internet. Por favor, verifica tu conexión.",
          data: {},
        });
        return;
      }
      setLoading(true);
      let res = await resendCodeTwoFactor(sessionStorage.getItem("userEmail"));

      if (res.isSucceed) setPedirTwoFactor(true);
      else
        onActualizar({
          OK: false,
          message: "Solicitud de Código Fallida",
          data: res,
        });
    } catch (error) {
      onActualizar({
        OK: false,
        message: "Solicitud de Código Fallida",
        data: error,
      });
    } finally {
      setLoading(false);
    }
  };
  const reenviarCodigoTwoFactor = async () => {
    try {
      if (!navigator.onLine) {
        onActualizar({
          OK: false,
          message:
            "No hay conexión a Internet. Por favor, verifica tu conexión.",
          data: {},
        });
        return;
      }
      setLoading(true);
      await resendCodeTwoFactor(sessionStorage.getItem("userEmail"));
      setMessageSuccess("El envío del código se ha realizado con éxito");
    } catch (error) {
      onActualizar({
        OK: false,
        message: "Solicitud de Código Fallida",
        data: error,
      });
    } finally {
      setTimeout(() => {
        setMessageSuccess(false);
      }, TIME_ALERT);
      setLoading(false);
    }
  };
  const actualizarPassword = async () => {
    try {
      if (!validacionPasswordPrevioEnvio()) return;
      setLoading(true);
      let res = await actualizarPasswordUser(
        emailUser,
        passActual,
        passNew,
        codigo ? codigo : ""
      );
      let data = await res.json();
      if (data.isSucceed) {
        onActualizar({ OK: true, message: "OK", data: data });
        sessionStorage.setItem(
          "lastPasswordChangedDate",
          new Date().toISOString()
        );
      } else {
        if (data.messages?.Password) {
          onActualizar({
            OK: false,
            message: data.messages?.Password[0],
            data: data,
            type: "password",
          });
        } else if (data.messages?.password) {
          onActualizar({
            OK: false,
            message: data.messages?.password[0],
            data: data,
            type: "password",
          });
        } else if (
          data.messages?.Code &&
          data.messages?.Code[0] === "Se requiere el codigo 2FA"
        ) {
          solicitarCodigoTwoFactor();
        } else if (
          data.messages?.Code &&
          data.messages?.Code[0] === "Invalid Code"
        ) {
          onActualizar({
            OK: false,
            message: "El código ingresado es incorrecto o ha expirado.",
            data: data,
            type: "Code",
          });
        } else if (data.messages?.Code) {
          onActualizar({
            OK: false,
            message: data.messages?.Code[0],
            data: data,
            type: "Code",
          });
        } else if (data?.messages) {
          let keys = Object.keys(data?.messages);
          if (keys.length > 0)
            onActualizar({
              OK: false,
              message: data.messages[keys[0]],
              data: data,
              type: "password",
            });
          else
            onActualizar({
              OK: false,
              message: "Actualización Fallida",
              data: data,
            });
        } else {
          onActualizar({
            OK: false,
            message: "Actualización Fallida",
            data: data,
          });
        }
      }
    } catch (error) {
      onActualizar({
        OK: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
        data: error,
      });
      setTimeout(() => {
        onActualizar({
          OK: false,
          message:
            "No hay conexión a Internet. Por favor, verifica tu conexión.",
          data: error,
        });
      }, TIME_ALERT);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!pedirTwoFactor ? (
        <ModalCardPages>
          <div className="d-flex flex-column gap-3 align-items-start col-6 p-0 padding_modal">
            <h4 className="card_title">Actualiza tu contraseña</h4>
            <p className="card_text">
              Para poder actualizar tu contraseña, necesitamos que ingreses tu
              contraseña actual por motivos de seguridad
            </p>
            <div className="container-fluid p-0 d-flex flex-column container-form-new-password">
              <InputPassword
                labelPassword="Contraseña actual"
                setPasswordProp={setPassActual}
              />
              <div className="container-fluid p-0">
                <p className="">Elige tu nueva contraseña</p>
              </div>
              <InputNewPassword
                setBanderaProp={setBanderaPass}
                setPasswordProp={setPassNew}
                id="new_password"
              />
              <InputPasswordConfirm
                labelPassword="Confirmar nueva contraseña"
                setPasswordProp={setPassConfirm}
                danger={passConfirm && passConfirm !== passNew}
                coincidence={passConfirm && passConfirm === passNew}
                id="new_password_confirmation"
              />
              <div
                className="container-fluid px-0"
                style={{ marginTop: "58px" }}
              >
                <button
                  type="button"
                  className={`button ${
                    validarPassword() ? "button_active" : "button_disabled"
                  } `}
                  disabled={!validarPassword()}
                  onClick={actualizarPassword}
                  data-testid={"update_password_button_submit"}
                >
                  Actualizar
                </button>
                {!disabledCancel && (
                  <button
                    type="button"
                    className='cancel_button'
                    data-testid={"cancel_button"}
                    onClick={onCancel}
                  >
                    Cancelar
                  </button>
                )}
              </div>
            </div>

            {/*<div className="container-fluid d-flex justify-content-center d-none">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p style={{ fontSize: "12px" }} className="fw-lighter m-0">
            ¿Necesitas ayuda?
          </p>
          <a
            className="fw-bold forgot_link"
            target="_blank"
            href="https://wa.me/5579211062?text=Hola,%20necesito%20ayuda"
          >
            Presiona aquí
          </a>
        </div>
      </div>*/}
          </div>
        </ModalCardPages>
      ) : (
        <ModalCardMedPages>
          <FormTwoFactor
            userName={nombreUser}
            cancelar={onCancel}
            reenviarCodigo={reenviarCodigoTwoFactor}
            setCodigo={setCodigo}
            enviarCodigo={actualizarPassword}
            passwordMessage={true}
          />
        </ModalCardMedPages>
      )}
      {loading && <Loader />}
      {messageSuccess && (
        <AlertaGeneral type={"success"}>{messageSuccess}</AlertaGeneral>
      )}
    </>
  );
};

export default CambioPassword;
