import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Dashboard } from "../../assets/images/Dashboard.svg"
import { ReactComponent as Ajustes } from "../../assets/images/Ajustes.svg"
import { ReactComponent as DB } from "../../assets/images/DB.svg"
import { ReactComponent as Nodos } from "../../assets/images/Nodos.svg"
import { ReactComponent as Transacciones } from "../../assets/images/Transacciones.svg"
import { ReactComponent as Usuarios } from "../../assets/images/Usuarios.svg"

export default function Link({ path, children }) {
  const catalogoLogos = {
    dashboard: (props) => <Dashboard {...props}/>,
    transacciones: (props) => <Transacciones {...props}/>,
    nodos: (props) => <Nodos {...props}/>,
    usuarios: (props) => <Usuarios {...props}/>,
    gestion: (props) => <DB {...props}/>,
    ajustes: (props) => <Ajustes {...props}/>,
  };
  return (
    <NavLink
      to={`/${path}`}
      data-testid={path}
      className={
        "dco_nav_link " +
        (window.location.pathname.replaceAll("/", "") === path &&
          "dco_nav_link_active")
      }
    >
      <span>{catalogoLogos[path]({...(path !== "ajustes" ? {fill: "inherit"} : {stroke: "inherit"})})}</span>
      {children}
    </NavLink>
  );
}
