import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import InputLabel from "./InputLabel";
import Tooltip from "../Inputs/Tooltip";
const useRequired = ({ required = false, regex = false, value = "" }) => {
  const [messageTooltip, setMessageTooltip] = useState("");
  const [showTooltip, setShowTooltip] = useState("");
  const [validInput, setValidInput] = useState(true);
  const [valorInput, setValorInput] = useState("");
  const focusedStart = useRef(false);
  useEffect(() => {
    if (value) {
      setValorInput(value);
    }
  }, []);
  useEffect(() => {
    if (!required) return;
    if (valorInput && !value) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
    }
  }, [value]);
  const handleFocusRequired = (focused) => {
    if (!required) return;
    if (!focusedStart.current && focused) {
      focusedStart.current = true;
    } else if (!valorInput && focusedStart.current && !focused) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
    }
  };
  const handleChangeValue = (value) => {
    if (!required) return;
    const valorInputCurrent = value;
    setValorInput(valorInputCurrent);
    if (regex?.length > 0) {
      for (const element of regex) {
        let reg = new RegExp(element.value);
        if (
          element.coincidencia
            ? !reg.test(valorInputCurrent)
            : reg.test(valorInputCurrent)
        ) {
          setShowTooltip(true);
          setMessageTooltip(element.message);
          setValidInput(false);
          return;
        }
      }
    }
    if (!value) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
      return;
    }
    setShowTooltip(false);
    setValidInput(true);
    setMessageTooltip("");
  };

  return {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  };
};
const OptionContainer = ({
  targetRef,
  position,
  options,
  valueKey,
  handleChange,
  labelKey,
  customStyles,
  id,
  ...props
}) => {
  return createPortal(
    <div
      ref={targetRef}
      className={`${
        props.rounded || customStyles
          ? "input_select_option_container_status"
          : "input_select_option_container_status"
      } shadow`}
      style={position}
    >
      {options.map((obj, index) => (
        <div
          key={`${obj[valueKey]}-${index}`}
          className={`d-flex justify-content-start
              ${
                props.rounded
                  ? "input_select_option_small"
                  : "input_select_option"
              }  z-3`}
          data-testid={`${id}-${obj[valueKey]}-${index}`}
          onClick={() => handleChange(obj[valueKey])}
        >
          {obj[labelKey]}
        </div>
      ))}
    </div>,
    document.getElementById("root")
  );
};

const useSelect = ({ handleSetValue, id, options, valueKey, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const targetRef = useRef(null);

  const [position, setPosition] = useState({ left: 0, top: 0 });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (selectedValue) => {
    handleSetValue({ field: id, value: selectedValue });
    setIsOpen(false);
  };

  const selectedOption = options.find((obj) => obj[valueKey] === value) || "";

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        targetRef.current &&
        containerRef.current &&
        !targetRef.current.contains(event.target)
      ) {
        if (!containerRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const updatePosition = () => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      setPosition({
        minWidth: containerRect.width,
        left: containerRect.left,
        top: containerRect.top + containerRect.height,
      });
    }
  };

  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);

    window.addEventListener("scroll", updatePosition);
    window.addEventListener("hashchange", updatePosition);
    window.addEventListener("wheel", updatePosition);
    window.addEventListener("orientationchange", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, [isOpen]);

  return {
    isOpen,
    handleChange,
    handleClick,
    selectedOption,
    containerRef,
    targetRef,
    position,
  };
};

const availableArrows = {
  dark: "/images/dicio/arrow_down.webp",
  white: "/images/icons/arrow_down_white.png",
};
export default function Select({
  error,
  value,
  handleSetValue,
  touched,
  id,
  testid,
  label,
  options,
  valueKey,
  labelKey,
  customStyles,
  arrow = "dark",
  arrowWidth = 10,
  arrowPosition = {},
  max_width,
  hide_current = false,
  icon = null,
  direction = {
    bottom: "0",
    left: "0",
    transform: "translateY(101%)",
  },
  claseSpan = "label_value_select",
  required = false,
  ...props
}) {
  const {
    isOpen,
    handleChange,
    handleClick,
    selectedOption,
    containerRef,
    targetRef,
    position,
  } = useSelect({
    handleSetValue,
    options,
    valueKey,
    id,
    value,
  });
  const inputError = !!(value !== "" && error);
  const inputValid = !!(value !== "" && !error);
  const labelProps = {
    focused: value !== "",
    inputError,
    inputValid,
    rounded: props.rounded,
  };

  const {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  } = useRequired({ required, value });

  const handleChangeGeneral = (params) => {
    handleChange(params);
    handleChangeValue(params);
  };

  useEffect(() => {
    handleFocusRequired(isOpen);
  }, [isOpen, handleFocusRequired]);

  return (
    <div
      ref={containerRef}
      className={`container-fluid p-0 ${customStyles ? "" : "mt-3"} ${
        options.length === 0 && "pe-none opacity-50"
      } position-relative`}
      style={{ marginLeft: "0" }}
    >
      {label !== "" && <InputLabel {...labelProps}>{label}</InputLabel>}
      <div
        className={`input_select  d-flex overflow-x-hidden justify-content-start ${
          props.rounded ? "input_select_small rounded-pill" : "input_select"
        }
        ${
          customStyles
            ? ""
            : ` border ${
                inputError
                  ? `border-danger`
                  : props.rounded
                  ? "border_for_small_inputs"
                  : `border-black`
              }`
        } `}
        onClick={handleClick}
        data-testid={`${testid}_button`}
        style={customStyles ? customStyles : {}}
      >
        <span
          className={claseSpan}
          style={{
            color: "inherit",
            fontSize: "inherit",
            fontWeight: "inherit",
            lineHeight: "inherit",
          }}
        >
          {selectedOption && selectedOption[labelKey]
            ? selectedOption[labelKey]
            : ""}
        </span>
      </div>
      {icon ? (
        icon
      ) : (
        <img
          src={availableArrows[arrow]}
          width={arrowWidth}
          height={"auto"}
          className={`${
            props.rounded ? "input_select_icon_small" : "input_select_icon"
          } ${
            isOpen
              ? props.rounded
                ? "input_select_icon_open_small"
                : "input_select_icon_open"
              : ""
          }`}
          alt="Select icon arrow"
          style={{
            cursor: "pointer",
            width: `${arrowWidth}px`,
            ...arrowPosition,
          }}
          onClick={handleClick}
        />
      )}
      {isOpen && (
        <OptionContainer
          id={id}
          targetRef={targetRef}
          position={position}
          options={
            hide_current ? options.filter((val) => val.id !== value) : options
          }
          valueKey={valueKey}
          handleChange={handleChangeGeneral}
          labelKey={labelKey}
          customStyles={customStyles}
          {...props}
        />
      )}
      {showTooltip && (
        <Tooltip valid={validInput} messageIncorrect={messageTooltip} />
      )}
    </div>
  );
}
