import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import dayjs from 'dayjs';
import { UserContext } from './Body';
import Checkbox from '../Inputs/Checkbox';
import Modal from '../Modal/Modal';
import AlertaGeneral from '../Alerts/AlertaGeneral';
import Loader from '../Loader/Loader';
import ReportePDF from 'dicio-npm-reporte-pdf';
import { formatDateToGMT06, TIME_ALERT } from '../../services/data.js';
import { dowloadPDFB64 } from '../../services/data.js';
import ExportButton from "../Sharing/ExportButton"

const useUserContext = () => useContext(UserContext);

const INITIAL_VALUES = {
  excel: '',
  csv: '',
  pdf: '',
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return {
        ...INITIAL_VALUES,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const useExportToExcel = () => {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const { statusOptions, handleExport, handleExportPDFBack } = useUserContext();
  const [propsPDF, setPropsPDF] = useState({});
  const modalRef = useRef(null);

  const handleModal = () => {
    modalRef?.current?.handleOpenModal();
  };

  const concatenarRoles = (roles) => {
    let rolesSalida = '';
    for (let index = 0; index < roles?.length; index++) {
      const element = roles[index];
      if (rolesSalida) {
        rolesSalida += ', ' + element.name;
      } else rolesSalida = element.name;
    }
    return rolesSalida;
  };

  const obtenerEncabezadoReporte = () => {
    return `Fecha y hora del reporte: ${new Date().toLocaleDateString()} ${new Date()
      .toTimeString()
      .substring(0, 8)} (GMT-6)`;
  };
  const prepararDatosExcel = (data) => {
    let newData = [
      [
        {
          value: obtenerEncabezadoReporte(),
          type: String,
        },
      ],
    ];
    //Header
    const headerStyle = {
      backgroundColor: '#2D5F7F',
      fontWeight: 'bold',
      color: '#FFFFFF',
      align: 'center',
    };
    newData.push([
      {
        value: 'Nombre(s)',
        type: String,
        ...headerStyle,
      },
      {
        value: 'Apellido Paterno',
        type: String,
        ...headerStyle,
      },
      {
        value: 'Apellido Materno',
        type: String,
        ...headerStyle,
      },
      {
        value: 'Correo',
        type: String,
        ...headerStyle,
      },
      {
        value: 'Puesto',
        type: String,
        ...headerStyle,
      },
      {
        value: 'Roles',
        type: String,
        ...headerStyle,
      },
      {
        value: 'Fecha de alta',
        type: String,
        ...headerStyle,
      },
      {
        value: 'Status',
        type: String,
        ...headerStyle,
      },
    ]);
    //Body
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      newData.push([
        {
          type: String,
          value: element.user.nombre,
        },
        {
          type: String,
          value: element.user.apellidoPaterno,
        },
        {
          type: String,
          value: element.user.apellidoMaterno,
        },
        {
          type: String,
          value: element.user.email,
        },
        {
          type: String,
          value: element.user.puesto,
        },
        {
          type: String,
          value: concatenarRoles(element?.roles) ?? '',
        },
        {
          type: String,
          value: dayjs(formatDateToGMT06(element.user.fechaAlta))
            .format('YYYY-MM-DD')
            .toString(),
          sortable: false,
        },
        {
          type: String,
          value: statusOptions.find((opt) => opt.id === element.user.status)
            ?.name,
        },
      ]);
    }
    return newData;
  };
  const excelDownload = async () => {
    let data = await handleExportPDFBack('xlsx');
    if (data) dowloadPDFB64(data, 'Usuarios', 'xlsx');
  };

  const csvDownloader = async () => {
    let data = await handleExportPDFBack('csv');
    if (data) dowloadPDFB64(data, 'Usuarios', 'csv');
  };

  const pdfDownloadFromBack = async () => {
    let data = await handleExportPDFBack('pdf');
    if (data) dowloadPDFB64(data, 'Usuarios', 'pdf');
  };

  const availableDownloads = {
    excel: excelDownload,
    csv: csvDownloader,
    pdf: pdfDownloadFromBack,
  };

  const handleDownload = async (type) => {
    try {
      if (!navigator.onLine) {
        throw new Error('NETWORK_CONNECTION');
      }
      await availableDownloads[type]();
      setSuccess(true);
      setSuccessMessage('La exportación de datos se ha realizado con éxito');
      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage('');
        handleModal();
      }, TIME_ALERT);
    } catch (error) {
      setError(true);
      if (error.message === 'NETWORK_CONNECTION') {
        setErrorMessage(
          'No hay conexión a Internet. Por favor, verifica tu conexión.',
        );
      } else if (error.message === 'LIMITE') {
        setErrorMessage('Limite de registros excedido');
      } else if (error.message === '') {
      } else {
        setErrorMessage('Ocurrió un error al exportar');
      }
      setTimeout(() => {
        setError(false);
        setErrorMessage('');
      }, TIME_ALERT);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selected = Object.entries(values).reduce((acc, [key, value]) => {
      if (value === 'true') {
        return key;
      }
      return acc;
    }, null);
    if (!selected) {
      setError(true);
      setErrorMessage('Por favor seleccione un formato de exportación');
      setTimeout(() => {
        setError(false);
        setErrorMessage('');
      }, TIME_ALERT);
      return;
    }
    handleDownload(selected);
  };
  const setFieldValue = (field, value) => {
    dispatch({ type: 'SET_FIELD', field, value });
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    setFieldValue(name, 'true');
  };

  useEffect(() => {
    setIsValid(
      Object.entries(values).reduce((acc, [key, value]) => {
        if (value === 'true') {
          return key;
        }
        return acc;
      }, null),
    );
  }, [values]);

  const getObjectDocument = (doc) => {
    doc.save(`Usuarios_del_nodo-Banco_Azteca.pdf`);
    setLoading(false);
    setPropsPDF({});
  };

  const getError = (error) => {
    console.log(`🚀 ----------------------------🚀`);
    console.log(`🚀 ~ getError ~ error:`, error);
    console.log(`🚀 ----------------------------🚀`);
  };

  const showRoles = (array) => {
    let string = '';
    if (array && array.length > 0) {
      array.forEach((element, index) => {
        if (index === 0) string += `${element.name}`;
        else string += `, ${element.name}`;
      });
    }
    return string;
  };

  return {
    isValid,
    values,
    modalRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    propsPDF,
    handleDownload,
    handleCheckbox,
    handleSubmit,
    handleModal,
  };
};

export default function ExportData() {
  const {
    isValid,
    values,
    modalRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    propsPDF,
    handleCheckbox,
    handleSubmit,
    handleModal,
  } = useExportToExcel();
  return (
    <>
      <Modal ref={modalRef}>
        <div
          className={
            'user_export_data_container bg-white rounded position-absolute top-50 start-50 translate-middle modal_export_users'
          }
        >
          <div className='container-fluid d-flex flex-column p-0 justify-content-start align-items-start modal_container_title'>
            <h4 className='main_title'>Usuarios</h4>
            <p className='main_subtitle'>
              Usuarios Ve el listado de usuarios, agrega o edita usuarios
            </p>
          </div>
          <div className='export_shadow_card container-fluid d-flex justify-content-center align-items-start p-0 flex-column'>
            <p className='card_title' style={{ marginBottom: '25px' }}>
              Exportación de información
            </p>
            <p className='card_text'>
              Se exportará la información seleccionada en los siguientes
              formatos:
            </p>
            <form onSubmit={handleSubmit} className='container p-0'>
              <div className='container-fluid p-0 d-flex gap-3'>
                <div className=''>
                  <Checkbox
                    id='excel'
                    name='excel'
                    label='XLS'
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.excel}
                  />
                </div>
                <div className=''>
                  <Checkbox
                    id='csv'
                    name='csv'
                    label='CSV'
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.csv}
                  />
                </div>
                <div className=''>
                  <Checkbox
                    id='pdf'
                    name='pdf'
                    label='PDF'
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.pdf}
                  />
                </div>
              </div>
              <div
                style={{ marginTop: '30px' }}
                className={'container-fluid p-0 d-flex gap-3'}
              >
                <button 
                  data-testid={"export_button_submit"}
                  className={`button button_active`} type='submit'>
                  Exportar
                </button>
                <button
                  type='button'
                  onClick={handleModal}
                  className='cancel_button'
                  data-testid={"cancel_button"}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <ExportButton onClick={handleModal} />
      {loading && <Loader />}
      {/* {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>} */}
      {success && (
        <AlertaGeneral type={'success'}>{successMessage}</AlertaGeneral>
      )}
      {Object.keys(propsPDF).length > 0 && <ReportePDF {...propsPDF} />}
    </>
  );
}
