import React, { useState, useEffect, useReducer } from "react";
import { getNodeInfo, getNodeMetrics } from "../../services/api";
import ToSkeleton from "../Skeleton/ToSkeleton";
import numeral from "numeral";
import dayjs from "dayjs";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import { formatToTwoDecimals, TIME_ALERT } from "../../services/data";

const Database = "/images/dashboard/dashboard_db.png";
const Alert = "/images/dashboard/cargas.svg";
const Transactions = "/images/dashboard/dashboard_transactions.png";
const TiempoRespuestaICON = "/images/dicio/Reloj.svg";
const CalidadFotografiaIcon = "/images/dicio/image_icon.svg";

const availableNodeStatus = {
  1: {
    class: "dco_graficas-estatus-tag",
    label: "Activo",
  },
  0: {
    class: "dco_graficas-estatus-tag-inactive",
    label: "Inactivo",
  },
};
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

const INITIAL_VALUES = {
  total_registers: "",
  total_transactions: "",
  total_loads: "",
  register_quality: "",
  photography_quality: "",
  average_response_time: "",
  val_id_score: "",
  node_status: 0,
};

const INITIAL_LOADING = {
  total_registers: false,
  total_transactions: false,
  total_loads: false,
  register_quality: false,
  photography_quality: false,
  average_response_time: false,
  val_id_score: false,
  node_status: false,
};

const INITIAL_ERRORS = {
  total_registers: false,
  total_transactions: false,
  total_loads: false,
  register_quality: false,
  photography_quality: false,
  average_response_time: false,
  val_id_score: false,
  node_status: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "SET_VALUES": {
      return {
        ...action.values,
      };
    }
    default:
      return state;
  }
};

const NodosComponent = () => {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [errors, dispatchErrors] = useReducer(formReducer, INITIAL_ERRORS);
  const [loading, dispatchLoading] = useReducer(formReducer, INITIAL_LOADING);
  const [error, setError] = useState("");

  const availbleDispatchers = {
    values: dispatch,
    errors: dispatchErrors,
    loading: dispatchLoading,
  };

  const setFieldValue = ({ dispatcher, field, value }) => {
    availbleDispatchers[dispatcher]({ type: "SET_FIELD", field, value });
  };
  const setValues = ({ dispatcher, values }) => {
    availbleDispatchers[dispatcher]({
      type: "SET_VALUES",
      values,
    });
  };

  const windowWidth = useWindowWidth();
  const cardClassName =
    windowWidth > 968 ? "dashboard-card-wide" : "dashboard-card-narrow";

  const handleGetNodeInfo = async () => {
    try {
      setFieldValue({
        dispatcher: "loading",
        field: "total_transactions",
        value: true,
      });
      setFieldValue({
        dispatcher: "loading",
        field: "photography_quality",
        value: true,
      });
      setFieldValue({
        dispatcher: "loading",
        field: "register_quality",
        value: true,
      });
      setFieldValue({
        dispatcher: "loading",
        field: "node_status",
        value: true,
      });
      setFieldValue({
        dispatcher: "loading",
        field: "total_registers",
        value: true,
      });
      setFieldValue({
        dispatcher: "loading",
        field: "total_loads",
        value: true,
      });
      setFieldValue({
        dispatcher: "loading",
        field: "average_response_time",
        value: true,
      });
      const response = await getNodeInfo();
      if (!response.success) {
        setFieldValue({
          dispatcher: "values",
          field: "node_status",
          value: "Inactivo",
        });
        setFieldValue({
          dispatcher: "errors",
          field: "node_status",
          value: true,
        });
        setFieldValue({
          dispatcher: "loading",
          field: "node_status",
          value: false,
        });
        setFieldValue({
          dispatcher: "errors",
          field: "total_registers",
          value: true,
        });
        setFieldValue({
          dispatcher: "loading",
          field: "total_registers",
          value: false,
        });
        setFieldValue({
          dispatcher: "values",
          field: "total_registers",
          value: "0",
        });
        setFieldValue({
          dispatcher: "errors",
          field: "total_transactions",
          value: true,
        });
        setFieldValue({
          dispatcher: "loading",
          field: "total_transactions",
          value: false,
        });
        setFieldValue({
          dispatcher: "values",
          field: "total_transactions",
          value: "0",
        });
        setFieldValue({
          dispatcher: "values",
          field: "total_loads",
          value: "0",
        });
        setFieldValue({
          dispatcher: "loading",
          field: "total_loads",
          value: false,
        });
        setFieldValue({
          dispatcher: "errors",
          field: "total_loads",
          value: true,
        });
        setFieldValue({
          dispatcher: "errors",
          field: "photography_quality",
          value: true,
        });
        setFieldValue({
          dispatcher: "loading",
          field: "photography_quality",
          value: false,
        });
        setFieldValue({
          dispatcher: "values",
          field: "photography_quality",
          value: "0",
        });
        setFieldValue({
          dispatcher: "values",
          field: "register_quality",
          value: "0",
        });
        setFieldValue({
          dispatcher: "loading",
          field: "register_quality",
          value: false,
        });
        setFieldValue({
          dispatcher: "errors",
          field: "register_quality",
          value: true,
        });
        setFieldValue({
          dispatcher: "values",
          field: "average_response_time",
          value: "",
        });
        setFieldValue({
          dispatcher: "loading",
          field: "average_response_time",
          value: false,
        });
        setFieldValue({
          dispatcher: "errors",
          field: "average_response_time",
          value: true,
        });

        setError(response?.message);
        setTimeout(() => {
          setError("");
        }, TIME_ALERT);
        return;
      }
      setFieldValue({
        dispatcher: "values",
        field: "register_quality",
        value: response?.data?.General?.calificacionNodo ?? "0",
      });
      setFieldValue({
        dispatcher: "loading",
        field: "register_quality",
        value: false,
      });
      setFieldValue({
        dispatcher: "values",
        field: "node_status",
        value: response?.data?.General?.estatusNodo ?? "0",
      });
      setFieldValue({
        dispatcher: "loading",
        field: "node_status",
        value: false,
      });
      setFieldValue({
        dispatcher: "values",
        field: "total_registers",
        value: response?.data?.General?.noRegistros ?? "0",
      });
      setFieldValue({
        dispatcher: "loading",
        field: "total_registers",
        value: false,
      });
      setFieldValue({
        dispatcher: "values",
        field: "total_transactions",
        value:
          response?.data?.General?.peticionesTransacciones
            ?.peticiones_validacion_identidad ?? "0",
      });
      setFieldValue({
        dispatcher: "loading",
        field: "total_transactions",
        value: false,
      });
      setFieldValue({
        dispatcher: "values",
        field: "total_loads",
        value: response?.data?.General?.no_cargas_total ?? "0",
      });
      setFieldValue({
        dispatcher: "loading",
        field: "total_loads",
        value: false,
      });
      setFieldValue({
        dispatcher: "values",
        field: "average_response_time",
        value: response?.data?.General?.tiempoPromedio ?? "0",
      });
      setFieldValue({
        dispatcher: "loading",
        field: "average_response_time",
        value: false,
      });
      setFieldValue({
        dispatcher: "values",
        field: "photography_quality",
        value: response?.data?.General?.calidad_foto_validacion ?? "0",
      });
      setFieldValue({
        dispatcher: "loading",
        field: "photography_quality",
        value: false,
      });
    } catch (error) {
      console.error(error);
      setFieldValue({
        dispatcher: "values",
        field: "node_status",
        value: "0",
      });
      setFieldValue({
        dispatcher: "errors",
        field: "node_status",
        value: true,
      });
      setFieldValue({
        dispatcher: "loading",
        field: "node_status",
        value: false,
      });
      setFieldValue({
        dispatcher: "errors",
        field: "total_registers",
        value: true,
      });
      setFieldValue({
        dispatcher: "loading",
        field: "total_registers",
        value: false,
      });
      setFieldValue({
        dispatcher: "values",
        field: "total_registers",
        value: "0",
      });
      setFieldValue({
        dispatcher: "errors",
        field: "total_transactions",
        value: true,
      });
      setFieldValue({
        dispatcher: "loading",
        field: "total_transactions",
        value: false,
      });
      setFieldValue({
        dispatcher: "values",
        field: "total_transactions",
        value: "0",
      });
      setFieldValue({
        dispatcher: "values",
        field: "total_loads",
        value: "0",
      });
      setFieldValue({
        dispatcher: "loading",
        field: "total_loads",
        value: false,
      });
      setFieldValue({
        dispatcher: "errors",
        field: "total_loads",
        value: true,
      });
      setFieldValue({
        dispatcher: "errors",
        field: "photography_quality",
        value: true,
      });
      setFieldValue({
        dispatcher: "loading",
        field: "photography_quality",
        value: false,
      });
      setFieldValue({
        dispatcher: "values",
        field: "photography_quality",
        value: "0",
      });
      setFieldValue({
        dispatcher: "values",
        field: "register_quality",
        value: "0",
      });
      setFieldValue({
        dispatcher: "loading",
        field: "register_quality",
        value: false,
      });
      setFieldValue({
        dispatcher: "errors",
        field: "register_quality",
        value: true,
      });
      setFieldValue({
        dispatcher: "values",
        field: "average_response_time",
        value: "0",
      });
      setFieldValue({
        dispatcher: "loading",
        field: "average_response_time",
        value: false,
      });
      setFieldValue({
        dispatcher: "errors",
        field: "average_response_time",
        value: true,
      });
      setError("Error al obtener los datos");
      setTimeout(() => {
        setError("");
      }, TIME_ALERT);
    }
  };

  useEffect(() => {
    handleGetNodeInfo();
  }, []);

  return (
    <div style={{ marginTop: "35px" }} className="w-100">
      <div
        style={{ marginBottom: "8px" }}
        className="d-flex flex-row align-items-center  flex-wrap"
      >
        <div
          className={"dco_graficas-subtitle"}
          style={{ fontSize: "10.8px", marginRight: "14px" }}
        >
          Estatus del nodo
        </div>
        <ToSkeleton
          style={{ width: "76px", height: "23px" }}
          loading={loading.node_status}
        >
          <div
            className={
              availableNodeStatus[values.node_status.toString()]?.class
            }
          >
            {availableNodeStatus[values.node_status.toString()]?.label}
          </div>
        </ToSkeleton>
        <div
          className="d-flex"
          style={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: "#05FF00",
            marginLeft: "19px",
            marginRight: "7px",
            border: "2px solid #04B400",
          }}
        ></div>
        <ToSkeleton
          style={{ width: "115px", height: "23px" }}
          loading={loading.register_quality}
        >
          <div
            className={"dco_graficas-subtitle"}
            style={{ fontSize: "8.83px" }}
          >
            Índice de calidad de nodo : {values.register_quality}
          </div>
        </ToSkeleton>
        <div
          className={"dco_graficas-subtitle"}
          style={{ fontSize: "8.83px", marginLeft: "19px" }}
        >
          {dayjs().utcOffset(-6).format("DD/MM/YYYY, HH:mm:ss [(GMT-6)]")}
        </div>
      </div>
      <div style={{ padding: "41px 21px" }} className="dashboard_cards">
        <div
          style={{
            gap: "10px",
            // marginTop: "23.86px",
            flexFlow: "wrap",
          }}
          className="container-fluid d-flex p-0"
        >
          <div
            style={{
              flex: "1 0 306px",
              padding: "14px 17px 13px 19px",
              gap: "12px",
            }}
            className="dashboard_cards nodos_card_info justify-content-between d-flex flex-column"
          >
            <div className="d-flex align-items-end" style={{ gap: "9px" }}>
              <img
                style={{ marginBottom: "0px" }}
                src={Database}
                alt="Database icon"
                width={"28px"}
                height={"auto"}
              />
              <ToSkeleton
                style={{ width: "100%", height: "36px" }}
                loading={loading.total_registers}
              >
                <p
                  className="node_title p-0"
                  style={{
                    opacity: ".8",
                    fontSize: "36px",
                    lineHeight: "28px",
                  }}
                >
                  {parseInt(values.total_registers) < 1000000
                    ? numeral(values.total_registers).format("0,0")
                    : numeral(values.total_registers).format("0a")}
                </p>
              </ToSkeleton>
            </div>
            <p
              className="dashboard_text m-0"
              style={{
                fontSize: "10px",
                fontWeight: "700",
                lineHeight: "7px",
                opacity: ".5",
              }}
            >
              Registros en el nodo
            </p>
          </div>
          <div
            style={{
              flex: `1 0 307px`,
              padding: "14px 17px 13px 19px",
              gap: "12px",
            }}
            className="dashboard_cards nodos_card_info justify-content-between d-flex flex-column"
          >
            <div
              className="d-flex align-items-end dashboard_petitions_text_container"
              style={{ gap: "54px" }}
            >
              <div className="d-flex align-items-end" style={{ gap: "9px" }}>
                <img
                  style={{ marginBottom: "0px" }}
                  src={Transactions}
                  alt="Database icon"
                  width={"28px"}
                  height={"auto"}
                />
                <ToSkeleton
                  style={{ width: "100%", height: "36px" }}
                  loading={loading.total_transactions}
                >
                  <p
                    className="node_title p-0"
                    style={{
                      opacity: ".8",
                      fontSize: "36px",
                      lineHeight: "28px",
                    }}
                  >
                    {parseInt(values.total_transactions) < 1000000
                      ? numeral(values.total_transactions).format("0,0")
                      : numeral(values.total_transactions).format("0a")}
                  </p>
                </ToSkeleton>
              </div>
            </div>
            <p
              className="dashboard_text m-0"
              style={{
                fontSize: "10px",
                fontWeight: "700",
                lineHeight: "7px",
                opacity: ".5",
              }}
            >
              Transacciones en el nodo
            </p>
          </div>
          <div
            style={{
              flex: "1 0 306px",
              padding: "14px 17px 13px 19px",
              gap: "12px",
            }}
            className="dashboard_cards nodos_card_info justify-content-between d-flex flex-column"
          >
            <div className="d-flex align-items-end" style={{ gap: "9px" }}>
              <img
                style={{ marginBottom: "0px" }}
                src={Alert}
                alt="Alert icon"
                width={"28px"}
                height={"auto"}
              />
              <ToSkeleton
                style={{ width: "100%", height: "36px" }}
                loading={loading.total_loads}
              >
                <p
                  className="node_title p-0"
                  style={{
                    opacity: ".8",
                    fontSize: "36px",
                    lineHeight: "28px",
                  }}
                >
                  {values.total_loads}
                </p>
              </ToSkeleton>
            </div>
            <p
              className="dashboard_text m-0"
              style={{
                fontSize: "10px",
                fontWeight: "700",
                lineHeight: "7px",
                opacity: ".5",
              }}
            >
              Cargas en el nodo
            </p>
          </div>
        </div>

        <div
          style={{
            gap: "10px",
            marginTop: "11px",
            flexFlow: "wrap",
          }}
          className="container-fluid d-flex p-0"
        >
          <div
            style={{
              flex: `1 0 307px`,
              padding: "14px 17px 13px 19px",
              gap: "12px",
            }}
            className="dashboard_cards nodos_card_info justify-content-between d-flex flex-column"
          >
            <div
              className="d-flex align-items-end dashboard_petitions_text_container"
              style={{ gap: "54px" }}
            >
              <div className="d-flex align-items-end" style={{ gap: "9px" }}>
                <img
                  style={{ marginBottom: "0px" }}
                  src={TiempoRespuestaICON}
                  alt="Database icon"
                  width={"28px"}
                  height={"auto"}
                />
                <ToSkeleton
                  style={{ width: "100%", height: "36px" }}
                  loading={loading.average_response_time}
                >
                  <p
                    className="node_title p-0"
                    style={{
                      opacity: ".8",
                      fontSize: "36px",
                      lineHeight: "28px",
                    }}
                  >
                    {values.average_response_time + " "}
                    <span
                      className="node_title p-0"
                      style={{
                        opacity: ".8",
                        fontSize: "18px",
                        lineHeight: "normal",
                        color: "var(--secondary-color)",
                      }}
                    >
                      seg
                    </span>
                  </p>
                </ToSkeleton>
              </div>
            </div>
            <p
              className="dashboard_text m-0"
              style={{
                fontSize: "10px",
                fontWeight: "700",
                lineHeight: "7px",
                opacity: ".5",
              }}
            >
              Promedio tiempo de respuesta
            </p>
          </div>
          <div
            style={{
              flex: "1 0 306px",
              padding: "14px 17px 13px 19px",
              gap: "12px",
            }}
            className="dashboard_cards nodos_card_info justify-content-between d-flex flex-column"
          >
            <div className="d-flex align-items-end" style={{ gap: "9px" }}>
              <img
                style={{ marginBottom: "0px" }}
                src={CalidadFotografiaIcon}
                alt="Database icon"
                width={"28px"}
                height={"auto"}
              />
              <ToSkeleton
                style={{ width: "100%", height: "36px" }}
                loading={loading.photography_quality}
              >
                <p
                  className="node_title p-0"
                  style={{
                    opacity: ".8",
                    fontSize: "36px",
                    lineHeight: "28px",
                  }}
                >
                  {formatToTwoDecimals(values.photography_quality)}
                </p>
              </ToSkeleton>
            </div>
            <p
              className="dashboard_text m-0"
              style={{
                fontSize: "10px",
                fontWeight: "700",
                lineHeight: "7px",
                opacity: ".5",
              }}
            >
              Promedio índice calidad de la fotografía
            </p>
          </div>

          {/* <div
            style={{
              flex: "1 0 306px",
              padding: "14px 17px 13px 19px",
              gap: "12px",
              visibility: "hidden",
            }}
            className="dashboard_cards nodos_card_info justify-content-between d-flex flex-column"
          ></div> */}
        </div>
      </div>
      {error && <AlertaGeneral type={"error"}>{error}</AlertaGeneral>}
    </div>
  );
};

export default NodosComponent;
