import React from "react";
import EditUser from "./EditUser";
import { Permisos } from "../../services/permisos";

export default function TableActions({ id, index, name, disabled = false }) {
  return (
    <div
      style={{ gap: "10px" }}
      className={`d-flex flex-wrap container-fluid p-0 table_user_container_actions`}
    >
      {Permisos.verificarPermiso("Usuario", "Actualizar") && (
        <EditUser id={id} disabled={disabled} index={index}>
          Ver/Editar
        </EditUser>
      )}
    </div>
  );
}
