/*Se agregan los diferentes endpoint y peticiones a servcios de BACK*/
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { toUtcTimeZone, translateTransactionsSerach, blobToB64, base64ToBlob } from "./data";
import { saveAs } from 'file-saver';
import { SingletonAPI } from "./singletonAPI";
import {enviroment, iniciarEnviroment, pagination_size } from "./enviromentConfig";

let endpoints = {};
let available_catalogs = {}
export const configEndpoints = ()=>{
  endpoints = {
    valid_email: enviroment.REACT_APP_SERVICE_VALID_EMAIL,
    login: enviroment.REACT_APP_SERVICE_LOGIN,
    login_two_factor: enviroment.REACT_APP_SERVICE_LOGIN_TWO_FACTOR,
    refresh_token: enviroment.REACT_APP_SERVICE_REFRESH_TOKEN,
    get_all_users: enviroment.REACT_APP_SERVICE_GET_ALL_USERS,
    export_all_users: enviroment.REACT_APP_SERVICE_EXPORT_ALL_USERS,
    get_all_logs: enviroment.REACT_APP_SERVICE_GET_ALL_LOGS,
    get_all_roles: enviroment.REACT_APP_SERVICE_GET_ALL_ROLES,
    delete_user: enviroment.REACT_APP_SERVICE_DELETE_USER,
    update_user: enviroment.REACT_APP_SERVICE_UPDATE_USER,
    create_user: enviroment.REACT_APP_SERVICE_CREATE_USER,
    getUserInfo: enviroment.REACT_APP_SERVICE_GETUSERINFO+"?idOrEmail=",
    forgot_password: enviroment.REACT_APP_SERVICE_FORGOT_PASSWORD,
    activate_user: enviroment.REACT_APP_SERVICE_ACTIVATE_USER,
    reset_forgotten_password: enviroment.REACT_APP_SERVICE_RESET_FORGOTTEN_PASSWORD,
    completeRegistro: enviroment.REACT_APP_SERVICE_COMPLETEREGISTRO,
    obs_file: enviroment.REACT_APP_SERVICE_OBS_FILE,
    linkObsFile: enviroment.REACT_APP_SERVICE_LINKOBSFILE,
    enableTwoFactor: enviroment.REACT_APP_SERVICE_ENABLETWOFACTOR,
    disableTwoFactor: enviroment.REACT_APP_SERVICE_DISABLETWOFACTOR,
    sendCode2faenable: enviroment.REACT_APP_SERVICE_SENDCODE2FAENABLE,
    sendCode2fadisable: enviroment.REACT_APP_SERVICE_SENDCODE2FADISABLE,
    resendCodeTwoFactor: enviroment.REACT_APP_SERVICE_RESENDCODETWOFACTOR,
    change_password: enviroment.REACT_APP_SERVICE_CHANGE_PASSWORD,
    get_management_dashboard: enviroment.REACT_APP_SERVICE_GET_MANAGEMENT_DASHBOARD,
    get_management_dashboard_export: enviroment.REACT_APP_SERVICE_GET_MANAGEMENT_DASHBOARD_EXPORT,
    get_management_error: enviroment.REACT_APP_SERVICE_GET_MANAGEMENT_ERROR+"?hash_carga=",
    get_all_privileges: enviroment.REACT_APP_SERVICE_GET_ALL_PRIVILEGES,
    create_rol: enviroment.REACT_APP_SERVICE_CREATE_ROL,
    add_rol_to_user: enviroment.REACT_APP_SERVICE_ADD_ROL_TO_USER,
    delete_rol_to_user: enviroment.REACT_APP_SERVICE_DELETE_ROL_TO_USER,
    //Gráficas
    get_statistics_by_date: enviroment.REACT_APP_SERVICE_GET_STATISTICS_BY_DATE,
    get_statistics_by_day: enviroment.REACT_APP_SERVICE_GET_STATISTICS_BY_DAY,
    get_validations_responses: enviroment.REACT_APP_SERVICE_GET_VALIDATIONS_RESPONSES,
    get_statistics_time_average: enviroment.REACT_APP_SERVICE_GET_STATISTICS_TIME_AVERAGE,
    get_statistics_score: enviroment.REACT_APP_SERVICE_GET_STATISTICS_SCORE,
    get_info_node: enviroment.REACT_APP_SERVICE_GET_INFO_NODE,
    get_node_metrics: enviroment.REACT_APP_SERVICE_GET_NODE_METRICS,
    //Transacciones
    get_allDataTransactions: enviroment.REACT_APP_SERVICE_GET_ALLDATATRANSACTIONS,
    // Catalogs
    event_logs_catalog: enviroment.REACT_APP_SERVICE_EVENT_LOGS_CATALOG,
    area_catalogo: enviroment.REACT_APP_SERVICE_AREA_CATALOGO,
    posicion_catalogo: enviroment.REACT_APP_SERVICE_POSICION_CATALOGO,
    status_catalog: enviroment.REACT_APP_SERVICE_STATUS_CATALOG,
    get_bitacora_pdf: enviroment.REACT_APP_SERVICE_EXPORT_BITACORA,
  };
  available_catalogs = {
    "rol": endpoints.get_all_roles,
    "areas": endpoints.area_catalogo,
    "positions": endpoints.posicion_catalogo,
    "status": endpoints.status_catalog,
    "event_logs": endpoints.event_logs_catalog
  }
}
iniciarEnviroment();
const bucket = "dco-bucket-assets-dev-mex2";

dayjs.extend(utc);
dayjs.extend(timezone);

export const checkUserTemporaryLocked = (lockoutend) => {
  const userTime = dayjs().tz(dayjs.tz.guess());
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const providedTimeUTC = dayjs.tz(lockoutend, 'UTC');
  const providedTimeCDMX = providedTimeUTC.tz(userTimeZone);
  const timeDifference = providedTimeCDMX.diff(userTime, 'seconds');
  const minutes = Math.floor(timeDifference / 60);
  const seconds = timeDifference % 60;

  return {
    time: `${minutes}:${seconds}`,
    locked: timeDifference > 0 ? true : false
  }

};





const isUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const activateUserService = async (values) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.activate_user}`, {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(values)
    });
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const { isSucceed } = await response.json();
    if (!isSucceed) {
      return {
        success: false,
        message: "La actualización del estatus del usuario no se a realizado con éxito."
      };
    }
    return {
      success: true,
      message: "La actualización del estatus del usuario se a realizado con éxito.",
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "La actualización del estatus del usuario no se a realizado con éxito.",
    };
  }
};

export const getManagementDashboard = async (init_date, final_date, search, page, page_size, signal, order, campo) => {
  try {
    const response = await fetch(endpoints.get_management_dashboard+`?fecha_inicio=${init_date}&fecha_fin=${final_date}&page=${page}&page_size=${page_size}${search?("&search="+encodeURIComponent(search)):""}${order?"&sort_order="+order:""}${campo?"&sort_by="+campo:""}`,
      {
        signal: signal?signal.signal:new AbortController().signal

      }
    );
    if (!response.ok) {
      if(response.status===404)
        return {
          success: true,
          message: "Catálogo obtenidos con éxito",
          data: [],
          pagination: {
            CurrentPage: 1,
            TotalPage: 1,
            PageSize: page_size,
            TotalCount: 0,
            HasPreviousPage: false,
            HasNextPage: false,
            NextPageNumber: false,
            PreviousPageNumber: null,
          }
        };
      else
        return {
          success: false,
          email: false,
        };
    }
    const response_2 = await response.json();
    if (!response_2) {
      console.error(`Error al obtener datos`);
      return {
        success: false,
        message: `Error al obtener datos`
      }
    }
    let total_page =response_2?.total_pages??1
    return {
      success: true,
      message: "Catálogo obtenidos con éxito",
      data: response_2?.pyload,
      pagination: {
        CurrentPage: response_2.current_page??1,
        TotalPage: total_page,
        PageSize: page_size,
        TotalCount: ((response_2.total_count * total_page)??10),
        HasPreviousPage: (response_2.current_page??1)>1 && total_page > 1,
        HasNextPage: (response_2.current_page??1)< total_page && total_page > 1,
        NextPageNumber:  (response_2.current_page??1)<total_page? (response_2.current_page??1) +1 : (total_page),
        PreviousPageNumber: (response_2.current_page??1)>1 ? (response_2.current_page??1) -1 : 1,
      }
    }
  } catch (error) {
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    if (error.name === 'AbortError') {
      return {
        success: false,
        message: "Lo sentimos ocurrió un error",
        name: 'AbortError'
      };
    } 
    console.error(error);
    return {
      success: false,
      message: `Error al obtener catálogo de`
    }
  }
};
export const getManagementDashboardExport = async (init_date, final_date, search, format, order, campo) => {
  try {
    const response = await fetch(endpoints.get_management_dashboard_export+`?fecha_inicio=${init_date}&fecha_fin=${final_date}&search=${encodeURIComponent(search)}&export_format=${format}${order?"&sort_order="+order:""}${campo?"&sort_by="+campo:""}`,
      {
        signal:new AbortController().signal

      }
    );
    if (!response.ok) {
      if(response.status===404)
        return {
          success: false,
          message: "Sin registros",
          data: [],
        };
      let error = await response.json();
      if(error && error?.length > 0){
        return {
          success: false,
          message: error[0].message_client || "El documento no pudo ser generado",
        };
      }
      else
        return {
          success: false,
          email: false,
        };
    }
    const response_2 = await response.blob();
    if (!response_2) {
      console.error(`Error al obtener datos`);
      return {
        success: false,
        message: `Error al obtener datos`
      }
    }
    return {
      success: true,
      message: "Export exitoso",
      data: response_2,
      
    }
  } catch (error) {
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    if (error.name === 'AbortError') {
      return {
        success: false,
        message: "Lo sentimos ocurrió un error",
        name: 'AbortError'
      };
    } 
    console.error(error);
    return {
      success: false,
      message: `Error al obtener catálogo de`
    }
  }
};
export const getManagementError = async (id) => {
  try {
    const response = await fetch(`${endpoints.get_management_error}${id}`);
    if (response.status === 404) {
      return {
        success: true,
        data: []
      };
    }
    if (!response.ok) {
      return {
        success: false,
        email: false,
      };
    }
    const response_2 = await response.json();
    if (!response_2) {
      console.error(`Error al obtener datos`);
      return {
        success: false,
        message: `Error al obtener datos`
      }
    }
    return {
      success: true,
      message: "Catálogo obtenidos con éxito",
      data: response_2
    }
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: `Error al obtener catálogo de`
    }
  }
};

export const getCatalogs = async (catalog) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(available_catalogs[catalog], {
      headers: myHeaders
    });
    if (!response.ok) {
      return {
        success: false,
        email: false,
      };
    }
    const { data, isSucceed } = await response.json();
    if (!isSucceed) {
      console.error(`Error al obtener catálogo de ${catalog}`);
      return {
        success: false,
        message: `Error al obtener catálogo de ${catalog}`
      }
    }
    return {
      success: true,
      message: "Catálogo obtenidos con éxito",
      data: data
    }
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    if (error.message === "NETWORK_CONNECTION") {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
        type:'NETWORK_CONNECTION'
      };
    }
    return {
      success: false,
      message: `Error al obtener catálogo de ${catalog}`
    }
  }
};

export const resetPassword = async (values) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.reset_forgotten_password}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(values)
    });
    if (!response.ok) {
      let res =  await response.json();
      if(res?.messages){
        let keys = Object.keys(res?.messages);
        if(keys.length>0)
          return{
            success: false,
            message: res?.messages[keys[0]],
            email: true
          }
        else return{
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde."
        }
      }
      else  
        return {
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde."
        };
      
    }
    const data = await response.json();
    if (!data.isSucceed) {
      if (data.messages?.password) {
        if(data.messages?.password[0]==="El password no puede ser el mismo al anterior")
          return {
            success: false,
            message: "La contraseña no debe ser la misma que la anterior"
          }
        else
          return {
            success: false,
            message: "La contraseña no debe incluir datos personales (nombre, apellidos)"
          }
      }
      else if (data.messages?.validation) {
        if(data.messages?.validation[0]==="El password no puede ser el mismo al anterior")
          return {
            success: false,
            message: "La contraseña no debe ser la misma que la anterior"
          }
        else
          return {
            success: false,
            message: data.messages?.validation[0]??"La contraseña no debe incluir datos personales (nombre, apellidos)"
          }
      }
      else {
        return {
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde"
        }
      }
    }
    return {
      success: true,
      message: "Contraseña actualizada con éxito"
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, intente más tarde"
    }
  }
}

export const forgotPassword = async (email) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.forgot_password}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ email })
    });
    if (!response.ok) {
      let res =  await response.json();
      if(res?.messages){
        let keys = Object.keys(res?.messages);
        if(keys.length>0)
          return{
            success: false,
            message: res?.messages[keys[0]],
            email: true
          }
        else return{
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde."
        }
      }
      else  
        return {
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde."
        };
    }
    const { isSucceed, ...props } = await response.json();
    if (!isSucceed) {
      return {
        success: false,
        message: props?.messages.hasOwnProperty("email") ? "El email proporcionado no existe" : "Lo sentimos ocurrió un error, intente más tarde",
        email: props?.messages.hasOwnProperty("email") ? true : false
      }
    }
    return {
      success: true,
      message: "Recuperación exitosa"
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, intente más tarde",
      email: false
    }
  }
}
export const getAllRolesService = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.get_all_roles}`, {
      headers: myHeaders
    });

    if (!response.ok) {
      return {
        success: false,
      };
    }
    const { data, isSucceed } = await response.json();
    if (!isSucceed) {
      console.error("Error al obtener catálogo de roles");
      return {
        success: false,
        message: "Error al obtener catálogo de roles"
      }
    }
    return {
      success: true,
      message: "Catálogos obtenidos con éxito",
      data: data
    }
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Error al obtener catálogo de roles"
    }
  }
};

export const getAllPrivilegesService = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.get_all_privileges}`, {
      headers: myHeaders
    });

    if (!response.ok) {
      return {
        success: false,
      };
    }
    const { data, isSucceed } = await response.json();
    if (!isSucceed) {
      console.error("Error al obtener catálogo de privilegios");
      return {
        success: false,
        message: "Error al obtener catálogo de privilegios"
      }
    }
    return {
      success: true,
      message: "Catálogos obtenidos con éxito",
      data: data
    }
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Error al obtener catálogo de roles"
    }
  }
};

export const createRolService = async (values) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.create_rol}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(values)
    });
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const { isSucceed, messages } = await response.json();
    if (!isSucceed) {
      if(messages?.validation)
        return {
          success: false,
          message: messages?.validation[0]??"El nuevo rol no ha sido creado"
        };
      else
        return {
          success: false,
          message: "El nuevo rol no ha sido creado"
        };
    }
    return {
      success: true,
      message: "El nuevo rol ha sido creado con éxito",
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "El nuevo rol no ha sido creado",
    };
  }
};

export const deleteUserById = async (id) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.delete_user}/${id}`, {
      method: "DELETE",
      headers: myHeaders
    });
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const { isSucceed } = await response.json();

    if (!isSucceed) {
      console.error("Error al eliminar usuario");
      return {
        success: false,
        message: "Error al eliminar usuario"
      }
    }
    return {
      success: true,
      message: "Usuario eliminado con éxito",
    }
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Error al eliminar usuario"
    }
  }
};
export const updateStatusUserById = async (id, status) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const reqBody = JSON.stringify(
      {"estatus":status}
    )
    const response = await fetch(`${endpoints.update_user}/${id}`, {
      method: "PUT",
      headers: myHeaders,
      body:reqBody
    });
    if (!response.ok) {
      const respuesta = await response.json()
      if(respuesta?.messages){
        let keys = Object.keys(respuesta?.messages);
        if(keys.length > 0)
          return {
            success: false,
            message: respuesta?.messages[keys[0]]
          }
        else
          return {
            success: false,
          };
      }
      else
        return {
          success: false,
        };
    }
    const { isSucceed } = await response.json();

    if (!isSucceed) {
      console.error("Error al eliminar usuario");
      return {
        success: false,
      }
    }
    return {
      success: true,
    }
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
    }
  }
};

export const getAllUsersService = async ({
  current_page,
  search,
  status,
  register_date,
  rol,
  limit,
  total_registers,
  signal,
  ...props
}) => {
  try {
    const token = sessionStorage.getItem("accessToken");
    if (!token) {
      return {
        success: false,
        message: 'El token de autorización no está disponible',
      };
    }
    
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    
    const url = `${endpoints.get_all_users}?${limit ? `PageSize=${pagination_size}${current_page ? `&PageNumber=${current_page}` : ``}` : `PageSize=-1&PageNumber=-1`}${register_date ? `&FechaAlta=${dayjs(register_date).format('YYYY-MM-DD').toString()}` : ``}${rol ? `&Rol=${rol}` : ``}${search ? `&Match=${encodeURIComponent(search)}` : ``}${status !== "" ? `&Status=${status}` : ``}${props.OrderAscending ? `&OrderAscending=${props.OrderAscending === 'asc' ? 'true' : 'false'}` :''}${props.OrderBy ? `&OrderBy=${props.OrderBy}`: ''}`;
    
    if (!endpoints.get_all_users) {
      return {
        success: false,
        message: 'Endpoint no definido correctamente',
      };
    }
    
    const response = await fetch(url, { headers: myHeaders, signal });
    
    if (!response.ok) {
      return {
        success: false,
      };
    }
    
    const responses = await response.json();
    const { isSucceed, data } = responses;
    
    if (!isSucceed) {
      if(responses?.messages?.Limit){
        return {
          success: false,
          message: responses?.messages?.Limit[0] || "Error al obtener los usuarios",
        };
      } else {
        return {
          success: false,
          message: "Error al obtener los usuarios",
        };
      }
    }
    
    return {
      success: true,
      message: "Datos de los usuarios obtenidos con éxito",
      data: data,
      pagination: JSON.parse(response.headers.get('x-pagination')),
    };
  } catch (error) {
    if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
      console.error('Fallo de la red o CORS:', error);
      return {
        success: false,
        message: "No hay conexión a Internet o la solicitud fue bloqueada.",
      };
    }
    if (error.name === 'AbortError') {
      return {
        success: false,
        message: "La solicitud fue cancelada",
        name: 'AbortError',
      };
    }
    
    console.error('Error desconocido:', error);
    return {
      success: false,
      message: "Error desconocido al obtener los usuarios",
    };
  }
};


export const exportAllUsersService = async ({
  search,
  status,
  register_date,
  rol,
  total_registers,
  signal,
  ...props
}) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.export_all_users}${props?.type}?${register_date ? `&FechaAlta=${dayjs(register_date).format('YYYY-MM-DD').toString()}` : ``}${rol ? `&Rol=${rol}` : ``}${search ? `&Match=${encodeURIComponent(search)}` : ``}${status ? `&Status=${status}` : ``}${props.OrderAscending ? `&OrderAscending=${props.OrderAscending === 'asc' ? 'true' : 'false'}` :''}${props.OrderBy ? `&OrderBy=${props.OrderBy}`: ''}`,
      { headers: myHeaders,
        signal: signal?signal.signal:new AbortController().signal
      }
    );
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const responses = await response.json();
    const {data, isSucceed} = responses;
    if(isSucceed)
        return {
        success: true,
        message: "Datos de los usuarios obtenidos con éxito",
        data: data,
      }
    else{
        if(responses?.messages?.Limit){
        return {
          success: false,
          message:responses?.messages?.Limit[0]||"Error al obtener los usuarios",
        }
      }
      else{
        return {
          success: false,
          message: "Error al exportar usuarios"
        };
      }
    }
    
      
  } catch (error) {
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    if (error.name === 'AbortError') {
      return {
        success: false,
        message: "Lo sentimos ocurrió un error",
        name: 'AbortError'
      };
    } 
    return {
      success: false,
      message: "Error al exportar usuarios"
    }
  }
};
export const validateEmailLogin = async (email, registro = false) => {
  try {
    const response = await fetch(`${endpoints.valid_email}?email=${email}`);
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const { isSucceed, data, ...back_response } = await response.json();
    if (!isSucceed) {
      return {
        success: false,
        error_message: back_response?.messages?.hasOwnProperty("email")
          ? "El correo ingresado no es válido, favor de verificarlo"
          : "Lo sentimos ocurrió un error",
      };
    }
    if (data?.status === 2) {
      return {
        success: false,
        error_message: "El usuario se encuentra bloqueado. Ponte en contacto con el administrador de tu cuenta o ve a ayuda para resolver tu caso",
      }
    }
    if (data?.status === 0) {
      return {
        success: false,
        error_message: "El usuario que estás utilizando, se encuentra inactivo. Ponte en contacto con el administrador de tu cuenta o ve a ayuda para resolver tu caso",
      }
    }
    if (data?.lockoutEnd) {
      const { time, locked } = checkUserTemporaryLocked(data?.lockoutEnd);
      if (locked) {
        return {
          success: false,
          error_message: `El usuario que estás utilizando, se encuentra bloqueado temporalmente. Por favor espera ${time} minutos para volver a intentarlo.`,
        }
      }
    }
    if (!data?.emailConfirmed && !registro) {
        return {
          success: false,
          error_message: `El usuario que estás utilizando, no ha terminado su registro, favor de completarlo.`,
        }
      
    }
    return {
      success: true,
      data,
    };
  } catch (error) {
    console.log(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        error_message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      error_message: "Lo sentimos ocurrió un error",
    };
  }
};

export const emailExist = async (email) => {
  try {
    const response = await fetch(`${endpoints.valid_email}?email=${email}`);
    if (!response.ok) {
      return false;
    }
    const { isSucceed } = await response.json();
    return isSucceed;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const login = async (values) => {
  try {
    const response = await fetch(endpoints.login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });

    if (!response.ok && response.status === 400) {
      let datos = await response.json()
      if (datos?.messages?.user) {
        const [details, count] = datos.messages.user;
        if (details === "RequiresTwoFactor") {
          return {
            success: true,
            error_message: "Login TwoFactor"
          };
        }
        else if (details === "User locked") {
          return {
            success: false,
            error_message: "User locked",
            bloqueado: true,
            contactar_admin : true

          };
        }
        else if (details === "LockedOut") {
          return {
            success: false,
            error_message: "User locked",
            bloqueado: true,

          };
        }
        else if (details === "User block timestimestamp") {
          return {
            success: false,
            error_message: "User locked timestimestamp",
            bloqueado: true,
            contactar_admin : false
          };
        }
        else {
          let nume_max_intentos = parseInt(count.replaceAll("AccessFailedCount: ", ""))>3 ?6 : 3;
          return {
            success: false,
            error_message: details === "Failed"
              ? (values?.twoFactorCode ? `El código ingresado es incorrecto o ha expirado. Intentos restantes ${nume_max_intentos - parseInt(count.replaceAll("AccessFailedCount: ", ""))}` : `La contraseña ingresada es incorrecta. Intentos restantes ${nume_max_intentos - parseInt(count.replaceAll("AccessFailedCount: ", ""))}`)
              : "Lo sentimos ocurrió un error, intente más tarde",
          };
        }
      }
    }
    else if (!response.ok) {
      throw Error("Service Error");
    }
    const data = await response.json();
    return {
      success: true,
      error_message: "Login exitoso",
      data: data,
    };
  } catch (error) {
    console.log(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        error_message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      error_message: "Lo sentimos ocurrió un error, intente más tarde",
    };
  }
};

export const login_two_factor = async (values) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(endpoints.login_two_factor, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(values),
    });
    const { isSucceed, data, ...back_response } = await response.json();
    if (!isSucceed) {
      return {
        success: false,
        message: back_response.messages?.hasOwnProperty("token")
          ? "Código de verificación incorrecto"
          : "Lo sentimos ocurrió un error, intente más tarde",
      };
    }
    return {
      success: true,
      message: "Login exitoso",
      data: data,
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, intente más tarde",
    };
  }
};
export const refreshToken = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(endpoints.refresh_token, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        refreshToken: sessionStorage.getItem("refreshToken"),
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    console.error(error);
    return false;
  }
};
export const peticionExample = () => {
  return fetch(endpoints.example);
}
export const getCatalogoAreas = () => {
  return fetch(endpoints.area_catalogo);
}
export const getCatalogoPosicion = () => {
  return fetch(endpoints.posicion_catalogo);
}

export const getInfoUser = (idOrEmail) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    /*return fetch(endpoints.getUserInfo + idOrEmail, {
      headers: myHeaders
    });*/
    return SingletonAPI.realizarSolicitud(endpoints.getUserInfo + idOrEmail,{
      headers: myHeaders
    })
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    if (error.message === "NETWORK_CONNECTION") {
      console.log("error.message", error.message);
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    return {
      success: false,
      message: "Error al obtener datos del usuario"
    }
  }
}

export const getByIDService = async (id) => {
  try {    
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    /*const response = await fetch(`${endpoints.getUserInfo}${id}`, {
      method: "GET",
      headers: myHeaders,
    });*/
    const response = await SingletonAPI.realizarSolicitud(`${endpoints.getUserInfo}${id}`, {
      method: "GET",
      headers: myHeaders,
    });

    const data = await response.json();
    if (!data.isSucceed) {
      console.error("Error al obtener datos del usuario");
      return {
        success: false,
        message: "Error al obtener datos del usuario"
      }
    }
    return {
      success: true,
      message: "Datos del usuario obtenidos con éxito",
      data: {
        ...data.data.user,
        area: data.data.user.areaId,
        puesto: data.data.user.puestoId
      },
      rol: data.data.roles[0].name
    }
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    if (error.message === "NETWORK_CONNECTION") {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    return {
      success: false,
      message: "Error al obtener datos del usuario"
    }
  }
};
export const completeRegistro = async  (userID, emailToken, passworToken, newPassword, imagenPerfilURL, areaID, puestoID, fecha) => {
  let imagenPerfilB64 ="";
    if (imagenPerfilURL) {
      imagenPerfilB64 = await blobToB64(imagenPerfilURL);
    }
  const raw = JSON.stringify({
    "userId": userID,
    "emailConfirmationToken": emailToken,
    "passwordResetToken": passworToken,
    "newPassword": newPassword,
    "imagenPerfil": imagenPerfilB64.split(",")[1]??"",//"https://dco-bucket-assets-dev-mex2.obs.myhuaweicloud.com/alliance_mvp/1bc0630d-e2f6-43c7-9f10-137bea9bffca/profile_photo.webp",
    "areaId": areaID,
    "puestoID": puestoID,
    "birthDate": fecha
  });
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "PATCH",
    body: raw,
    redirect: "follow",
    headers: myHeaders,
  };

  return fetch(endpoints.completeRegistro, requestOptions);
}
export const subirArchivoOBSBlobFolder = async (blobImg, apikey, folder, nombreFile) => {
  try {
    let controller = new AbortController();
    let header = new Headers();
    header.append("apikey", apikey);
    header.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    let data = new FormData();
    data.append("file", blobImg, nombreFile);
    data.append("folder", folder);
    data.append("bucket", bucket);
    let response = await fetch(endpoints.obs_file, {
      method: 'post',
      headers: header,
      body: data,
      signal: controller.signal
    });
    let link = await obtenerURLOBSFolder(apikey, folder, nombreFile);
    let salida = { OK: response.ok, status: response.status, data: response, url: (await link.json()).payload.link };
    return salida;
  } catch (error) {
    let salida = { OK: false, status: "Error Network", error: error };
    return salida;
  }

}
const obtenerURLOBSFolder = async (apikey, folder, nombreFile) => {
  let header = new Headers();
  header.append("apikey", apikey);
  header.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
  return fetch(endpoints.linkObsFile + `&folder=${folder}&namefile=${nombreFile}`, {
    method: 'get',
    headers: header,
  });
}
export const actualizarPasswordUser = (email, oldPass, newPass, codigo) => {
  const myHeaders = new Headers();
  myHeaders.append("accept", "text/plain");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);

  const raw = JSON.stringify({
    "code": codigo,
    "oldPassword": oldPass,
    "newPassword": newPass
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  return fetch(endpoints.change_password, requestOptions)
}
export const activarTwoFactor = (email, activar = true) => {
  const myHeaders = new Headers();
  myHeaders.append("accept", "text/plain");
  myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  return fetch(`${activar ? endpoints.enableTwoFactor : endpoints.disableTwoFactor}?email=${email}`, requestOptions);
}
export const enviarCodigoTwoFactor = (codigo, email, activar = true) => {
  const myHeaders = new Headers();
  myHeaders.append("accept", "text/plain");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);

  const raw = JSON.stringify({
    "email": email,
    "code": codigo
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  return fetch(activar ? endpoints.sendCode2faenable : endpoints.sendCode2fadisable, requestOptions)
}
export const resendCodeTwoFactor = async (email) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(endpoints.resendCodeTwoFactor + "?email=" + email, {
      method: "GET",
      headers: myHeaders,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    console.error(error);
    return false;
  }
};
export const addRolUserService = async (values) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.add_rol_to_user}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(values)
    });
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const { isSucceed } = await response.json();

    if (!isSucceed) {
      return {
        success: false,
        message: "Lo sentimos ocurrió un error, al agregar roles",
      };
    }
    return {
      success: true,
      message: "La actualización del estatus del usuario se a realizado con éxito.",
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, al agregar roles",
    };
  }
};

export const deleteRolUserService = async (values) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.delete_rol_to_user}`, {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify(values)
    });
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const { isSucceed } = await response.json();

    if (!isSucceed) {
      return {
        success: false,
        message: "Lo sentimos ocurrió un error, al eliminar roles",
      };
    }
    return {
      success: true,
      message: "La actualización del estatus del usuario se a realizado con éxito.",
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, al eliminar roles",
    };
  }
};

export const editUserService = async (values) => {
  try {
    /*if (values.profile_image && !isUrl(values.profile_image) && values.extension_file) {
      let res = await subirArchivoOBSBlobFolder(
        values.profile_image,
        bucket_api_key,
        `alliance_mvp/${values.id}`,
        "profile." + values.extension_file
      );
      if (!res.OK) {
        return {
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde",
        };
      }
    }*/
    let imagenPerfilB64 ="";
    if (values?.profile_image) {
      imagenPerfilB64 = await blobToB64(values?.profile_image);
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const reqBody = JSON.stringify({
      ...(values.name ? { nombre: values.name } : {}),
      ...(values.paternal_surname ? { apellidoPaterno: values.paternal_surname } : {}),
      ...(values.maternal_surname ? { apellidoMaterno: values.maternal_surname } : {}),
      ...(values.birdth_date ? { fechaNacimiento: values.birdth_date } : {}),
      //...(values.profile_image ? { imagenPerfil: !isUrl(values.profile_image) ? `https://${bucket}.obs.myhuaweicloud.com/alliance_mvp/${values.id}/profile.${values.extension_file}` : values.profile_image } : {}),
      ...(values.profile_image ? { imagenPerfil: (imagenPerfilB64.split(",")[1]) ?? ""}:{} ),
      ...(values.phone ? { telefono: values.phone } : {}),
      ...(values.permissions ? { rol: values.permissions } : {}),
      ...(values.area ? { areaId: parseInt(values.area) } : {}),
      ...(values.position ? { puestoId: parseInt(values.position) } : {}),
      ...(values.roleIds ? { roleIds: values.roleIds } : {}),
      ...(values.estatus ? { estatus: values.estatus } : {}),
    })
    const response = await fetch(`${endpoints.update_user}/${values.id}`, {
      method: "PUT",
      headers: myHeaders,
      body: reqBody
    });
    if (!response.ok) {
    const { isSucceed, messages } = await response.json();
      if(messages?.validations)
        return {
          success: false,
          message: messages?.validations[0],
        };
      else
        return {
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde",
        };
    }
    const { isSucceed, messages } = await response.json();

    if (!isSucceed) {
      if(messages?.validations)
        return {
          success: false,
          message: messages?.validations[0],
        };
      else
        return {
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde",
        };
    }
    return {
      success: true,
      message: "La actualización del estatus del usuario se a realizado con éxito.",
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, intente más tarde",
    };
  }
};

export const createUserService = async ({
  name,
  paternal_surname,
  maternal_surname,
  phone,
  email,
  roleIds,
}) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.create_user}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        nombre: name,
        apellidoPaterno: paternal_surname,
        apellidoMaterno: maternal_surname,
        email,
        telefono: phone,
        extension: "52",
        roleIds,
      })
    });

    if (!response.ok) {
      return {
        success: false,
      };
    }
    const { isSucceed, messages } = await response.json();
    if (!isSucceed) {
      if(messages?.DuplicateUserName)
        return {
          success: false,
          message: "El correo ya se encuentra registrado en el sistema",
        };
      else if(messages?.validations)
        return {
          success: false,
          message: messages?.validations[0],
        };
      else
        return {
          success: false,
          message: "Lo sentimos ocurrió un error, intente más tarde",
        };
    }
    return {
      success: true,
      message: "Usuario creado con éxito",
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, intente más tarde",
    };
  }
};

const errorMessages = {
  "NETWORK_CONNECTION": "No hay conexión a Internet. Por favor, verifica tu conexión."
}

const errorResponses = (type) => ({
  success: false,
  message: errorMessages[type] ?? "Lo sentimos ocurrió un error"
})

//Charts - Dashboard
export const getStatisticsByDate = async ({
  start_date,
  end_date,
  start_hours,
  end_hours
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error('NETWORK_CONNECTION');
    }
    const body = JSON.stringify({
      ...(start_date !== '' && end_date !== '' ? { startDate: toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString(), endDate: toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString() } : {}),
    });
    let response = await fetch(`${endpoints.get_statistics_by_date}`, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();
    return {
      success: true,
      data: result
    }
  } catch (error) {
    return errorResponses(error.message);
  }
}

export const getStatisticsValidation = async ({
  start_date,
  end_date,
  start_hours,
  end_hours
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error('NETWORK_CONNECTION');
    }
    const body = JSON.stringify({
      ...(start_date !== '' && end_date !== '' ? { startDate: toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString(), endDate: toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString() } : {}),
    });
    let response = await fetch(`${endpoints.get_validations_responses}`, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body
    })
    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();
    return {
      success: true,
      data: result
    }
  } catch (error) {
    return errorResponses(error.message);
  }
}

export const getStatisticsTimeAverage = async ({
  start_date,
  end_date,
  start_hours,
  end_hours
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error('NETWORK_CONNECTION');
    }
    const body = JSON.stringify({
      ...(start_date !== '' && end_date !== '' ? { startDate: toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString(), endDate: toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString() } : {}),
    });
    let response = await fetch(`${endpoints.get_statistics_time_average}`, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body
    })
    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();
    return {
      success: true,
      data: result
    }
  } catch (error) {
    return errorResponses(error.message);
  }
}

export const getStatisticsByDay = async ({
  start_date,
  end_date,
  start_hours,
  end_hours
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error('NETWORK_CONNECTION');
    }
    const body = JSON.stringify({
      ...(start_date !== '' && end_date !== '' ? { startDate: toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString(), endDate: toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString() } : {}),
    });
    let response = await fetch(`${endpoints.get_statistics_by_day}`, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body
    })
    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();
    return {
      success: true,
      data: result
    }
  } catch (error) {
    return errorResponses(error.message);
  }
}

export const getStatisticsScore = async (startDate, endDate) => {
  let data = { success: false, data: {}, message: '' }
  try {
    let response = await fetch(`${endpoints.get_statistics_score}`, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        startDate: startDate,
        endDate: endDate
      })
    })
    let result = await response.json()
    result.Respuesta ? 
      data = { success: false, data: result, message: 'Consulta éxitosa - Datos incorrectos' }
    :
      data = { success: true, data: result, message: 'Consulta éxitosa' }
  } catch (error) {
    console.warn(`✨ ~ file: api.js:1128 ~ getStatisticsScore ~ error:`, error)
    if (error.message === "NETWORK_CONNECTION" || error.message === 'Failed to fetch')
      data = { success: false, data: {}, message: "No hay conexión a Internet. Por favor, verifica tu conexión." }
    else
      data = { success: false, data: {}, message: 'Error desconocido, por favor intenta nuevamente.' }
  } finally {
    return data;
  }
}

//Transactions 
export const getDataTransactions = async ({
  page,
  search,
  start_date,
  end_date,
  start_hours,
  end_hours,
  response_time,
  signal,
  photo_quality,
  ...props
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const encodedURL = (`${endpoints.get_allDataTransactions}?page=${page}&perPage=${pagination_size}${response_time.length === 2 ?  `&maxmintiemporespuestafield=tiempos_servicios.instruccion_validar_embeddings&mintiemporespuestavalue=${response_time[0]}&maxtiemporespuestavalue=${response_time[1]}` : ''}${search !== '' ? `&search=${encodeURIComponent(translateTransactionsSerach(search))}` : ''}${start_date !== '' && end_date !== '' ? `${start_date !== '' || start_hours !== '' ? `&startDate=${toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString()}` : ''}${end_date !== '' || end_hours !== '' ? `&endDate=${toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString()}` : ''}` : ''}${props.OrderAscending ? `&order=${props.OrderAscending === 'asc' ? 'desc' : 'asc'}` :''}${props.OrderBy ? `&sort=${props.OrderBy}`: ''}${photo_quality.length === 2 ? `&maxminfotocalidadpromediofield=foto_calidad_promedio&minfotocalidadpromediovalue=${photo_quality[0]}&maxfotocalidadpromediovalue=${photo_quality[1]}` : ''}`);
    
    const response = await fetch(encodedURL, {
      method: "GET",
      headers: myHeaders,
      signal
    });
    if (!response.ok) {
      return {
        success: false,
        message: 'Lo sentimos ocurrió un error'
      };
    }
    const { payload } = await response.json();
    return {
      data: payload.items ?? [],
      success: true,
      message: "Datos obtenidos con éxito",
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    if (error.message === "NETWORK_CONNECTION") {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    if (error.name === 'AbortError') {
      console.log('Request canceled');
      return {
        success: false,
        message: "Lo sentimos ocurrió un error",
        name: 'AbortError'
      };
    } 
    return {
      success: false,
      message: "Lo sentimos ocurrió un error",
    };
  }
}

export const downloadTransactions = async ({
  search,
  start_date,
  end_date,
  start_hours,
  end_hours,
  response_time,
  signal,
  photo_quality,
  download,
  ...props
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const response = await fetch(`${endpoints.get_allDataTransactions}?${response_time.length === 2 ?  `&maxmintiemporespuestafield=tiempos_servicios.instruccion_validar_embeddings&mintiemporespuestavalue=${response_time[0]}&maxtiemporespuestavalue=${response_time[1]}` : ''}${search !== '' ? `&search=${encodeURIComponent(translateTransactionsSerach(search))}` : ''}${start_date !== '' && end_date !== '' ? `${start_date !== '' || start_hours !== '' ? `&startDate=${toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString()}` : ''}${end_date !== '' || end_hours !== '' ? `&endDate=${toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString()}` : ''}` : ''}${props.OrderAscending ? `&order=${props.OrderAscending === 'asc' ? 'desc' : 'asc'}` :''}${props.OrderBy ? `&sort=${props.OrderBy}`: ''}${photo_quality.length === 2 ? `&maxminfotocalidadpromediofield=foto_calidad_promedio&minfotocalidadpromediovalue=${photo_quality[0]}&maxfotocalidadpromediovalue=${photo_quality[1]}` : ''}${download.params}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (!response.ok) {
      let error = await response.json();
      if(error && error?.length > 0){
        return {
          success: false,
          message: error[0].message_client || "El documento no pudo ser generado",
        };
      }
      return {
        success: false,
        message: "El documento no pudo ser generado",
      };
    }
    const data = await response.blob()
    saveAs(data, download.name);
    return {
      success: true
    };
  } catch (error) {
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    if (error.message === "NETWORK_CONNECTION") {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    if (error.response.message_client.includes("maximo")){
      return {
        success: false,
        message: error.response.message_client,
      };
    }
    console.error(error);
    return false;
  }
}
// Node
export const getNodeInfo = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const response = await fetch(`${endpoints.get_info_node}?startDate=${toUtcTimeZone(dayjs().startOf('year').format("YYYY-MM-DD HH:mm:ss"))}&endDate=${toUtcTimeZone(dayjs().format("YYYY-MM-DD HH:mm:ss"))}`, {
      method: "GET",
      headers: myHeaders,
      /*body: JSON.stringify({
        "startDate": toUtcTimeZone(dayjs().subtract(90, 'day').format("YYYY-MM-DD HH:mm:ss")),
        "endDate": toUtcTimeZone(dayjs().format("YYYY-MM-DD HH:mm:ss"))
    })*/
    });
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const parsedResponse = await response.json();
    return {
      data: parsedResponse ?? {},
      success: true,
      message: "Datos obtenidos con éxito",
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Lo sentimos ocurrió un error de red",
    };
  }
}

export const getTransactions = async () => {
  try {
    const response = await fetch(endpoints.get_transactions);
    if (!response.ok) {
      return {
        success: false,
        email: false,
      };
    }
    const response_2 = await response.json();
    if (!response_2) {
      console.error(`Error al obtener datos`);
      return {
        success: false,
        message: `Error al obtener datos`
      }
    }
    return {
      success: true,
      message: "Catálogo obtenido con éxito",
      data: response_2
    }
  }
  catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, al agregar roles",
    };
  }
}
export const getNodeMetrics = async () => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const response = await fetch(`${endpoints.get_node_metrics}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const parsedResponse = await response.json();
    return {
      data: parsedResponse ?? {},
      success: true,
      message: "Datos obtenidos con éxito",
    };
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    return {
      success: false,
      message: "Lo sentimos ocurrió un error, al agregar roles",
    };
  }
}

// Bitacora

export const getAllLogsService = async ({
  current_page,
  search,
  start_date,
  end_date,
  start_hours,
  end_hours,
  limit,
  signal,
  type_event,
  total_registers,
  ...props
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);
    const response = await fetch(`${endpoints.get_all_logs}?${limit ? `itemsPerPage=${pagination_size}${current_page ? `&PageNumber=${current_page}` : ``}` : `itemsPerPage=-1&PageNumber=-1`}${props.OrderAscending ? `&ascending=${props.OrderAscending === 'asc' ? 'false' : 'true'}` :''}${props.OrderBy ? `&orderBy=${props.OrderBy}`: ''}${start_date !== '' && end_date !== '' ? `${start_date !== '' || start_hours !== '' ? `&fechaInicio=${toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString()}` : ''}${end_date !== '' || end_hours !== '' ? `&fechaFin=${toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString()}` : ''}` : ''}${type_event ? `&logLevel=${type_event}`: ''}${search ? `&searchText=${encodeURIComponent(search)}`: ''}`,
      { 
        headers: myHeaders,
        signal 
      }
    );
    if (!response.ok) {
      return {
        success: false,
      };
    }
    const respuesta = await response.json();
    const { isSucceed, data } = respuesta;
    if (!isSucceed) {
      console.error("Error al obtener los usuarios");
      if(respuesta?.messages?.Limit)
        return {
          success: false,
          message:respuesta?.messages?.Limit[0] || "Error al obtener los datos",
        }
      else if(respuesta?.messages){
        let keys = Object.keys(respuesta?.messages);
        if(keys.length > 0)
          return {
            success: false,
            message: respuesta?.messages[keys[0]],
          }
        else return {
          success: false,
          message: "Error al obtener los datos",
        }
      }
      else{
        return {
          success: false,
          message: "Error al obtener los datos",
        }
      }
    }
    return {
      success: true,
      message: "Datos de los usuarios obtenidos con éxito",
      data: data,
      pagination: JSON.parse(response.headers.get('x-pagination'))
    }
  } catch (error) {
    console.error(error);
    if (error.name === 'TypeError' && error.message==='Failed to fetch') {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión."
      }
    }
    if (error.message === "NETWORK_CONNECTION") {
      return {
        success: false,
        message: "No hay conexión a Internet. Por favor, verifica tu conexión.",
      };
    }
    if (error.name === 'AbortError') {
      console.log('Request canceled');
      return {
        success: false,
        message: "Lo sentimos ocurrió un error al obtener las transacciones",
        name: 'AbortError'
      };
    }
    return {
      success: false,
      message: "Error al obtener los usuarios"
    }
  }
};

export const exportBitacoraService = async ({
  current_page,
  search,
  start_date,
  end_date,
  start_hours,
  end_hours,
  limit,
  signal,
  total_registers,
  type_event,
  ...props
}) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("accessToken")}`);

    const response = await fetch(`${endpoints.get_bitacora_pdf}${props?.type}?${start_date !== '' && end_date !== '' ? `${start_date !== '' || start_hours !== '' ? `&fechaInicio=${toUtcTimeZone(`${start_date} ${start_hours !== '' ? start_hours : '00:00'}`).toISOString()}` : ''}${end_date !== '' || end_hours !== '' ? `&fechaFin=${toUtcTimeZone(`${end_date} ${end_hours !== '' ? end_hours : '00:00'}`).toISOString()}` : ''}` : ''}${type_event ? `&logLevel=${type_event}`: ''}${search ? `&searchText=${encodeURIComponent(search)}`: ''}${props.OrderAscending ? `&ascending=${props.OrderAscending === 'asc' ? 'false' : 'true'}` :''}${props.OrderBy ? `&orderBy=${props.OrderBy}`: ''}`,
    {
      headers: myHeaders,
      signal
    });
    if (!response.ok) {
      const errorText = await response.text(); // Captura el texto del error
      console.error("Error en la respuesta:", errorText);
      return { success: false, message: "Error al obtener los datos" };
    }

    const result = await response.json();

    const exportResponse = result;

    if (!exportResponse.data) {
      if(exportResponse?.messages){
        let keys = Object.keys(result?.messages);
        if(keys.length > 0)
          throw new Error(result?.messages[keys[0]]);
        else
          throw new Error("La respuesta no contiene datos válidos.");
      }
      else
        throw new Error("La respuesta no contiene datos válidos.");
    }
    return { success: true, data: exportResponse.data };
  } catch (error) {
    console.error("Error en exportBitacoraService:", error.message || error);
    return { success: false, message: error.message };
  }
};

export const getImageFromURL = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error al cargar la imagen: ${response.statusText}`);
    }

    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result); // Esto será el data URL
      reader.onerror = () => reject(new Error("Error al leer la imagen"));
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};