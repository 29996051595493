import React, { useState, useEffect, useRef } from "react";
import TooltipPassword from "../RecuperarPassword/TooltipPassword";
import { verificarPassword } from "../../services/data";

const InputNewPassword = ({
  setPasswordProp = () => {},
  setBanderaProp = () => {},
  id
}) => {
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [mostrarTooltip, setMostrarTooltip] = useState(false);
  const [posicionTooltip, setPosicionTooltip] = useState([0, 0]);
  const [validaciones, setValidaciones] = useState({
    min: false,
    oneUpperCase: false,
    oneLowerCase: false,
    noSecuence: false,
    noSpace: false,
    vacio: true,
    oneSpecialCaracter: false,
    minStrong: false,
  });
  const [bandera, setBandera] = useState("blank");
  const [focusPassword, setFocusPassword] = useState(false);
  const buttonTooltip = useRef();

  useEffect(() => {
    if (validaciones) {
      if (
        validaciones.oneLowerCase &&
        validaciones.noSpace &&
        validaciones.oneUpperCase &&
        validaciones.noSecuence &&
        validaciones.min
      ) {
        if (validaciones.minStrong) setBandera("strong");
        else setBandera("green");
      } else if (
        validaciones.oneLowerCase &&
        validaciones.noSpace &&
        validaciones.oneUpperCase
      ) {
        setBandera("yellow");
      } else if (validaciones.oneLowerCase && validaciones.noSpace) {
        setBandera("red");
      } else if (password) {
        setBandera("red");
      } else {
        setBandera("blank");
      }
    }
  }, [validaciones, password]);
  useEffect(() => {
    setBanderaProp(bandera);
  }, [bandera]);

  const manejadorPassword = (e) => {
    setPassword(e.target.value);
    setPasswordProp(e.target.value);
    setValidaciones(verificarPassword(e.target.value));
  };
  const alternarMostrarPassword = (e) => {
    setPasswordVisible((prevState) => !prevState);
  };
  const abrirTooltip = (e) => {
    setPosicionTooltip([
      buttonTooltip?.current?.getBoundingClientRect()?.x + 10,
      buttonTooltip?.current?.getBoundingClientRect()?.y,
    ]);
    setMostrarTooltip(true);
  };
  const cerrarTooltip = (e) => {
    setMostrarTooltip(false);
    setPosicionTooltip([0, 0]);
  };
  return (
    <>
      <div
        className="m-0 d-flex flex-column container-fluid p-0 position-relative"
        style={{ zIndex: "1" }}
      >
        <div className="container-fluid p-0 position-relative">
          <div
            className={`position-absolute pe-none input_label 
            ${bandera === "yellow" || bandera === "red" ? " text-danger" : ""}
                ${
                  !focusPassword
                    ? "input_label_inside_input"
                    : "input_label_outside_input"
                }
                input_label_error`}
          >
            Nueva contraseña
          </div>
          <input
            autoComplete="off"
            label="Nueva contraseña"
            name="password"
            className={`input bg-transparent ${
              bandera === "yellow" || bandera === "red"
                ? "border-danger text-danger"
                : ""
            }`}
            type={passwordVisible ? "text" : "password"}
            onChange={manejadorPassword}
            onFocus={() => {
              setFocusPassword(true);
              abrirTooltip();
            }}
            onBlur={() => {
              !password && setFocusPassword(false);
              cerrarTooltip();
            }}
            data-testid={id}
          />
          <button
            onClick={alternarMostrarPassword}
            className="input_watch_password_button bg-transparent"
            type="button"
            data-testid={`${id}_watch_button`}
          >
            <img
              src={
                passwordVisible
                  ? "/images/dicio/invisible.webp"
                  : "/images/dicio/visible.webp"
              }
              alt="watch password icon"
              width="14"
              height="auto"
            />
          </button>
        </div>
        <div
          style={{ marginTop: "6px" }}
          className="container-fluid mt-1 p-0 d-flex flex-column justify-content-center align-items-center"
        >
          <div className="row container-fluid password_requirements_container">
            <span
              className="col rounded"
              style={{
                background:
                  bandera === "red"
                    ? "#FF0000"
                    : bandera === "yellow"
                    ? "#F4BC38"
                    : bandera === "green" || bandera === "strong"
                    ? "#0DA60A"
                    : "#D9D9D9",
              }}
            ></span>
            <span
              className="col rounded"
              style={{
                background:
                  bandera === "yellow"
                    ? "#F4BC38"
                    : bandera === "green" || bandera === "strong"
                    ? "#0DA60A"
                    : "#D9D9D9",
              }}
            ></span>
            <span
              className="col rounded"
              style={{
                background:
                  bandera === "green" || bandera === "strong"
                    ? "#0DA60A"
                    : "#D9D9D9",
              }}
            ></span>
            <span
              className="col rounded"
              style={{
                background: bandera === "strong" ? "#0DA60A" : "#D9D9D9",
              }}
            ></span>
          </div>
          <div
            className="container-fluid p-0 d-flex justify-content-end align-items-center"
            style={{
              position: "relative",
              marginTop: "4px",
              marginBottom: "12px",
            }}
          >
            <p className="password_requirements_strenght_label my-0 mx-2">
              {bandera === "red"
                ? "Muy débil"
                : bandera === "yellow"
                ? "Débil"
                : bandera === "green"
                ? "Buena"
                : bandera === "strong"
                ? "Excelente"
                : "Seguridad de la contraseña"}
            </p>
            <div>
              <button
                ref={buttonTooltip}
                type="button"
                className="tooltip_password_validator_button d-flex"
                /*onMouseOver={abrirTooltip}
                onMouseOut={cerrarTooltip}*/
                style={{ maxHeight: "12px" }}
                disabled
              >
                <img
                  src="/images/dicio/info.webp"
                  alt="Info icon"
                  width="12px"
                  height="12px"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      {mostrarTooltip && (
        <TooltipPassword
          x={posicionTooltip[0]}
          y={posicionTooltip[1]}
          validaciones={validaciones}
        />
      )}
    </>
  );
};

export default InputNewPassword;
