import React, { useEffect, useState, useRef } from "react";
import Tooltip from "./Tooltip";
const useRequired = ({ required = false, regex = false, value = "" }) => {
  const [messageTooltip, setMessageTooltip] = useState("");
  const [showTooltip, setShowTooltip] = useState("");
  const [validInput, setValidInput] = useState(true);
  const [valorInput, setValorInput] = useState("");
  const focusedStart = useRef(false);
  useEffect(() => {
    if (value) {
      setValorInput(value);
    }
  }, []);
  useEffect(() => {
    if (!required) return;
    if (valorInput && !value) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
    }
  }, [value]);
  const handleFocusRequired = (focused) => {
    if (!required) return;
    if (!focusedStart.current && focused) {
      focusedStart.current = true;
    } else if (!valorInput && focusedStart.current && !focused) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
    }
  };
  const handleChangeValue = (value) => {
    if (!required) return;
    const valorInputCurrent = value;
    setValorInput(valorInputCurrent);
    if (regex?.length > 0) {
      for (const element of regex) {
        let reg = new RegExp(element.value);
        if (
          element.coincidencia
            ? !reg.test(valorInputCurrent)
            : reg.test(valorInputCurrent)
        ) {
          setShowTooltip(true);
          setMessageTooltip(element.message);
          setValidInput(false);
          return;
        }
      }
    }
    if (!value) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
      return;
    }
    setShowTooltip(false);
    setValidInput(true);
    setMessageTooltip("");
  };

  return {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  };
};
const InputSelect = ({
  setValueProp = () => {},
  labelProp = "Área",
  options = [
    { id: 0, name: "Por Definir" },
    { id: 1, name: "Area 1" },
    { id: 1, name: "Area 2" },
  ],
  idKey = "id",
  labelKey = "name",
  rounded,
  initial_value = "",
  max_width,
  icon = null,
  required = false,
  id
}) => {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [label, setLabel] = useState("");
  const [initialValueSet, setInitialValueSet] = useState(false);

  useEffect(() => {
    setValueProp(selectedOption);
  }, [selectedOption]);

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleChange = (value) => {
    setSelectedOption(value);
    setLabel(value[labelKey]);
    setIsOpen(false);
  };

  useEffect(() => {
    setInitialValueSet(false);
    setLabel("");
    setSelectedOption("");
  }, [options]);

  useEffect(() => {
    verificarValorInicial();
  }, [initial_value]);

  useEffect(() => {
    verificarValorInicial();
  }, [initialValueSet]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setIsOpen]);
  const verificarValorInicial = () => {
    if (initial_value !== "" && !initialValueSet) {
      const selected = options.filter(
        (object) => object[idKey] === initial_value
      );
      if (selected && selected.length > 0) {
        setSelectedOption(selected[0]);
        setLabel(selected[0][labelKey]);
        setInitialValueSet(true);
      }
    }
  };
  const {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  } = useRequired({ required, value: selectedOption });

  const handleChangeGeneral = (params) => {
    handleChange(params);
    handleChangeValue(params);
  };

  useEffect(() => {
    handleFocusRequired(isOpen);
  }, [isOpen, handleFocusRequired]);
  return (
    <>
      <div
        ref={containerRef}
        // className="container-fluid p-0 mt-3 pe-none opacity-50 position-relative"
        className="container-fluid p-0 mt-3 position-relative"
      >
        <div
          className={`position-absolute pe-none overflow-hidden
          ${rounded ? "input_label_small" : "input_label"}
                ${
                  label
                    ? "input_label_outside_input"
                    : "input_label_inside_input"
                }
                false`}
        >
          {labelProp}
        </div>
        <div
          onClick={handleClick}
          style={{
            border: rounded ? "1px solid #D9D9D9" : "1px solid #000000",
          }}
          className={`${
            rounded ? "input_select_small rounded-pill" : "input_select"
          }  d-flex overflow-hidden justify-content-start ${
            rounded ? "rounded-pill" : ""
          }`}
          data-testid={id}
        >
          <span
            className="overflow-hidden"
            style={{ maxWidth: `${max_width - 30}px`, textWrap: "nowrap" }}
          >
            {label}
          </span>
        </div>
        {icon ? (
          <img
            src={icon}
            width="10"
            height="auto"
            className={`${"input_select_icon_small"}`}
            alt="Select icon arrow"
            style={{ cursor: "pointer", width: "10px" }}
          />
        ) : (
          <img
            src="/images/dicio/arrow_down.webp"
            width="10"
            height="auto"
            className={`${
              rounded ? "input_select_icon_small" : "input_select_icon"
            } ${
              isOpen
                ? rounded
                  ? "input_select_icon_open_small"
                  : "input_select_icon_open"
                : ""
            }`}
            alt="Select icon arrow"
            style={{ cursor: "pointer" }}
          />
        )}
        {isOpen && options.map && (
          <div
            className={`${
              rounded
                ? "input_select_option_container_small"
                : "input_select_option_container_small"
            } shadow`}
          >
            {options.map((obj, index) => (
              <div
                style={{ textWrap: "nowrap" }}
                key={`${obj[idKey]}-${index}`}
                data-testid={`${id}-${obj[idKey]}-${index}`}
                className={`d-flex justify-content-start
                ${
                  rounded ? "input_select_option_small" : "input_select_option"
                }  z-3`}
                onClick={() => handleChangeGeneral(obj)}
              >
                {obj[labelKey]}
              </div>
            ))}
          </div>
        )}
        {showTooltip && (
          <Tooltip valid={validInput} messageIncorrect={messageTooltip} />
        )}
      </div>
    </>
  );
};

export default InputSelect;
