import React, { useEffect, useState } from "react";

const InputCodeTwoFactor = ({setCodeProps, validacionInput}) => {
    const [codigo, setCodigo] = useState(["?","?","?","?","?","?"]);
    useEffect(() => {
        if(setCodeProps){
            let codeString = "";
            for (let index = 0; index < codigo.length; index++) {
                const element = codigo[index];
                codeString+=element;
            }
            setCodeProps(codeString);
        }
    }, [codigo])
    
    const manejadorCodigo = (e, position)=>{
        let code = e.target.value;
        setCodigo(prevSate=>{
            let sal = JSON.parse(JSON.stringify(prevSate));
            sal[position]=code;
            return sal;
        });

        let inputs = document.querySelectorAll('.input_code');
        if(inputs.length > (position +1))
            if(!inputs[position + 1].value)
                inputs[position + 1].focus();
    } 
    const manejadorFocusCodigo = (e, position)=>{
        e.target.value = "";
        setCodigo(prevSate=>{
            let sal = JSON.parse(JSON.stringify(prevSate));
            sal[position]="?";
            return sal;
        });
    } 
    const manejadorPasteCode = (e, position)=>{
        let paste = e.clipboardData.getData("text").replace(/ /g, "");
        let inputs = document.querySelectorAll('.input_code');
        for (let i = 0; i < paste.length; i++) {
            const el = paste[i];
            if (inputs.length > (position + i) ) {
                inputs[i+position].value = el;
                setCodigo(prevSate=>{
                    let sal = JSON.parse(JSON.stringify(prevSate));
                    sal[i+position]=el;
                    return sal;
                });
            }
            else{
                break;
            }
        }
    } 
    return (
      <div className="w-100">
         <div className={"dco_2f_container_code"}>
                {/* <div className={`dco_2f_input_code row`}> */}
                <div className={`dco_2f_input_code`}>
                  <div className="col padding-left-0">
                    <input data-testid="input_code_0" className={`input_code ${validacionInput ? 'border-error':''}`} onPaste={(e)=>{manejadorPasteCode(e, 0)}} onFocus={(e)=>{manejadorFocusCodigo(e, 0) }} onChangeCapture={(e)=>{manejadorCodigo(e, 0)}} type="tel" maxLength={1}/>
                  </div>
                  <div className="col">
                    <input data-testid="input_code_1" className={`input_code ${validacionInput ? 'border-error':''}`} onPaste={(e)=>{manejadorPasteCode(e, 1)}} onFocus={(e)=>{manejadorFocusCodigo(e, 1)}} onChangeCapture={(e)=>{manejadorCodigo(e, 1)}} type="tel" maxLength={1}/>
                  </div>
                  <div className="col">
                    <input data-testid="input_code_2" className={`input_code ${validacionInput ? 'border-error':''}`} onPaste={(e)=>{manejadorPasteCode(e, 2)}} onFocus={(e)=>{manejadorFocusCodigo(e, 2)}} onChangeCapture={(e)=>{manejadorCodigo(e, 2)}} type="tel" maxLength={1}/>
                  </div>
                  <div className="col">
                    <input data-testid="input_code_3" className={`input_code ${validacionInput ? 'border-error':''}`} onPaste={(e)=>{manejadorPasteCode(e, 3)}} onFocus={(e)=>{manejadorFocusCodigo(e, 3)}} onChangeCapture={(e)=>{manejadorCodigo(e, 3)}} type="tel" maxLength={1}/>
                  </div>
                  <div className="col">
                    <input data-testid="input_code_4" className={`input_code ${validacionInput ? 'border-error':''}`} onPaste={(e)=>{manejadorPasteCode(e, 4)}} onFocus={(e)=>{manejadorFocusCodigo(e, 4)}} onChangeCapture={(e)=>{manejadorCodigo(e, 4)}} type="tel" maxLength={1}/>
                  </div>
                  <div className="col padding-right-0 ">
                    <input data-testid="input_code_5" className={`input_code ${validacionInput ? 'border-error':''}`} onPaste={(e)=>{manejadorPasteCode(e, 5)}} onFocus={(e)=>{manejadorFocusCodigo(e, 5)}} onChangeCapture={(e)=>{manejadorCodigo(e, 5)}} type="tel" maxLength={1}/>
                  </div>
                </div>
              </div>
      </div>
    )
}

export default InputCodeTwoFactor;