import React from 'react'
import { ReactComponent as Plus } from "../../assets/images/plus.svg"

export default function CreateButton({ children, ...props }) {
  return (
    <button type="button" className={`d-flex flex-nowrap text-nowrap gap-1 create_button`} {...props}>
      { children }
      <Plus />
    </button>
  )
}
