import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useCallback,
  useRef,
} from "react";
import FilterForm from "./FilterForm";
import Table from "../Table/Container";
import Pagination from "../Table/Pagination.jsx";
import TableActions from "./TableActions";
import UserStatus from "./UserStatus";
import UserProfilePhoto from "./UserProfilePhoto";
import {
  getAllUsersService,
  exportAllUsersService,
  getCatalogs,
} from "../../services/api";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import CreateUser from "./CreateUser";
import {
  handleGetCatalogs,
  toUserTimeZone,
  useLoaderReducer,
  useErrorReducer,
  useValuesReducer,
  formatDateToGMT06,
} from "../../services/data";
import ExportData from "./ExportData";
import CreateRol from "./CreateRol";
import { Permisos } from "../../services/permisos";
import usePaginationBack from "../Table/usePaginationBack";
import { enviroment } from "../../services/enviromentConfig";
import LimpiarFiltros from "../Sharing/LimpiarFiltros.jsx";
import RolUser from "./RolUser.jsx";

const limite_registros = enviroment.REACT_APP_CONSTANT_LIMIT_EXPORT_USER || 100;
export const UserContext = createContext();

export const handleExportUsers = async ({
  values,
  service,
  loading,
  error,
}) => {
  try {
    loading("increment");
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const response = await service(values);
    if (!response.success) {
      loading("decrement");
      error(response.message);
      console.error("Error request");
      return;
    }
    loading("decrement");
    return response.data;
  } catch (err) {
    loading("decrement");
    if (error.message === "NETWORK_CONNECTION") {
      error("No hay conexión a Internet. Por favor, verifica tu conexión.");
      return;
    }
    error("Lo sentimos ocurrió un error, intente más tarde");
    console.error("Error request");
    console.error(err);
    return;
  }
};

const INITIAL_VALUES = {
  search: "",
  status: "",
  register_date: "",
  rol: "",
};

export const handleGetAllUsers = async ({
  values,
  service,
  loading,
  error,
  handleValidateTotalPages,
  setTableData,
  signal,
}) => {
  try {
    if (signal.current) {
      signal.current.abort();
    }
    signal.current = new AbortController();
    loading("increment");
    const response = await service({
      ...values,
      signal: signal.current.signal,
    });
    if (!response.success) {
      loading("decrement");
      if (response.name === "AbortError") {
        console.log("Request canceled");
        return;
      }
      error(response.message);
      console.error("Error request");
      return;
    }
    loading("decrement");
    handleValidateTotalPages({
      totalPages: response.pagination.TotalPage,
      page: response.pagination.CurrentPage,
      totalElements: response.pagination.TotalCount,
    });
    setTableData(response.data);
  } catch (err) {
    loading("decrement");
    error("Lo sentimos ocurrió un error, intente más tarde");
    console.error("Error request");
    console.error(err);
    return;
  }
};

export const handleExportAllUsersPDF = async ({
  values,
  service,
  loading,
  error,
}) => {
  try {
    loading("increment");
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const response = await service(values);
    if (!response.success) {
      loading("decrement");
      if (response.name === "AbortError") return false;
      error(response.message);
      console.error("Error request");
      return false;
    }
    loading("decrement");
    return response.data;
  } catch (err) {
    loading("decrement");
    if (err.message === "NETWORK_CONNECTION") {
      error("No hay conexión a Internet. Por favor, verifica tu conexión.");
      return;
    }
    error("Lo sentimos ocurrió un error, intente más tarde");
    console.error("Error request");
    console.error(err);
    return false;
  }
};

const mappingOrderKeys = {
  nombre: "Nombre",
  apellidoPaterno: "ApellidoPaterno",
  apellidoMaterno: "ApellidoMaterno",
  email: "Correo",
  status: "Estatus",
  fechaAlta: "FechaAlta",
  puesto: "Puesto",
  roles: "Roles",
};

export default function Body() {
  const [tableData, setTableData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [refreshRol, setRefreshRol] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [rolOptions, setRolOptions] = useState([]);
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(null);
  const { errorValues, handleError } = useErrorReducer();
  const { loaderValues, handleLoader } = useLoaderReducer();
  const { createHandleChange, createHandleDatePickerChange, values } =
    useValuesReducer(INITIAL_VALUES);
  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();
  const [orderConfig, setOrderConfig] = useState({
    order: "asc",
    orderBy: "nombre",
  });

  // Handlers
  const handleRefreshData = () => {
    handleResetPagination();
    setRefreshData((prevState) => !prevState);
  };

  const handleRefreshRol = () => {
    setRefreshRol((prevState) => !prevState);
  };

  const handleChange = createHandleChange(handleResetPagination);

  const handleDatePickerChange = createHandleDatePickerChange(
    handleResetPagination
  );

  const handleExport = async () => {
    if (pagination?.totalElements > limite_registros) {
      throw new Error("LIMITE");
    }
    const data = await handleExportUsers({
      values: {
        limit: false,
        total_registers: pagination?.TotalCount,
        OrderAscending: orderConfig.order,
        OrderBy: mappingOrderKeys[orderConfig.orderBy],
        ...values,
      },
      loading: handleLoader,
      error: handleError,
      service: getAllUsersService,
    });
    if (data) return data;
    else throw new Error("");
  };
  const handleExportPDFBack = async (type) => {
    const data = await handleExportAllUsersPDF({
      values: {
        ...values,
        OrderAscending: orderConfig.order,
        OrderBy: mappingOrderKeys[orderConfig.orderBy],
        type,
      },
      loading: handleLoader,
      error: handleError,
      service: exportAllUsersService,
    });
    if (data) return data;
    else throw new Error("");
  };

  useEffect(() => {
    handleGetCatalogs({
      service: getCatalogs,
      setter: setStatusOptions,
      catalog: "status",
    });
  }, []);

  useEffect(() => {
    handleGetCatalogs({
      service: getCatalogs,
      setter: setRolOptions,
      catalog: "rol",
    });
  }, [refreshRol]);

  // Effects
  useEffect(() => {
    handleGetAllUsers({
      values: {
        ...values,
        current_page: pagination.page,
        limit: true,
        OrderAscending: orderConfig.order,
        OrderBy: mappingOrderKeys[orderConfig.orderBy],
      },
      loading: handleLoader,
      error: handleError,
      handleValidateTotalPages,
      setTableData,
      service: getAllUsersService,
      signal: abortControllerRef,
    });
  }, [refreshData, pagination.page, orderConfig, values]);

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Avatar",
        custom_cell: ({ user }) => (
          <UserProfilePhoto src={"data:image/*;base64," + user.imagenPerfil} />
        )
      },
      {
        name: "Nombre(s)",
        selector: ({ user }) => user.nombre,
        key_name: "nombre",
        service: handleOrderByColumns,
      },
      {
        name: "Apellido Paterno",
        selector: ({ user }) => user.apellidoPaterno,
        key_name: "apellidoPaterno",
        service: handleOrderByColumns,
      },
      {
        name: "Apellido Materno",
        selector: ({ user }) => user.apellidoMaterno,
        key_name: "apellidoMaterno",
        service: handleOrderByColumns,
      },
      {
        name: "Correo electrónico",
        selector: ({ user }) => user.email,
        key_name: "email",
        service: handleOrderByColumns,
      },
      {
        name: "Roles",
        selector: ({ roles }) => {
          return <RolUser roles={roles} />;
        },
        service: handleOrderByColumns,
        key_name: "roles",
        cell_styles: { whiteSpace: "nowrap"}
      },
      {
        name: "Fecha de alta",
        selector: ({ user }) =>
          toUserTimeZone(formatDateToGMT06(user.fechaAlta)).format(
            "YYYY-MM-DD"
          ),
        key_name: "fechaAlta",
        service: handleOrderByColumns
      },

      {
        name: "Estatus",
        custom_cell: ({ user, index, roles }) => (
          <div
            className={
              "w-100 p-0 m-0 " +
              (Permisos.verificarPermiso("Usuario", "Cambiar Estatus")
                ? ""
                : "pe-none")
            }
          >
            <UserStatus
              status={user.status}
              id={user.id}
              name={`${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`}
              rolesUser={roles}
              index={index}
              setDataTable={setTableData}
            />
          </div>
        ),
        selector: ({ user }) => user.status,
        service: handleOrderByColumns,
        key_name: "status",
      },
      {
        name: "Acciones",
        custom_cell: ({ user, index }) => (
          <TableActions
            id={user.id}
            name={`${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`}
            disabled={user.status === 0}
            index={index}
          />
        )
      },
    ],
    [handleOrderByColumns]
  );

  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={dataTable}
        header_classname="table_header"
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    columns,
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    orderConfig,
    pagination,
  ]);

  return (
    <UserContext.Provider
      value={{
        handleRefreshData,
        handleExport,
        statusOptions,
        rolOptions,
        handleRefreshRol,
        handleExportPDFBack,
      }}
    >
      {Permisos.verificarPermiso("Usuario", "Ver la sección") && (
        <div
          className="container-fluid d-flex flex-column p-0 module_users"
          style={{ position: "relative" }}
        >
          <div
            style={{ marginBottom: "11px" }}
            className="d-flex justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end mt-3"
          >
            {Permisos.verificarPermiso("Usuario", "Buscar") && (
              <FilterForm
                {...{
                  handleDatePickerChange,
                  handleChange,
                  values,
                  INIT_VALUES: INITIAL_VALUES,
                }}
              />
            )}
            <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap mt-3">
              <LimpiarFiltros
                handleChange={handleChange}
                INITIAL_VALUES={INITIAL_VALUES}
                modulo="Usuario"
              />
              {Permisos.verificarPermiso("Usuario", "Crear Rol") && (
                <CreateRol />
              )}
              {Permisos.verificarPermiso("Usuario", "Crear usuario") && (
                <CreateUser />
              )}
              {Permisos.verificarPermiso("Usuario", "Exportar") && (
                <ExportData />
              )}
            </div>
          </div>

          {memoizedUserTable}
        </div>
      )}
      {loaderValues.loading > 0 && <Loader />}
      {errorValues.timeLeft > 0 && (
        <AlertaGeneral type={"error"}>{errorValues.currentError}</AlertaGeneral>
      )}
    </UserContext.Provider>
  );
}
