import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Loader from '../Loader/Loader';
import PageHeader from '../Pages/Header';
import PageTitle from '../Pages/Title';
import PageSubtitle from '../Pages/Subtitle';
import CopyCell from './CopyCell';
import ValidationLabel from './ValidationLabel';
import {
  useCopy,
  formatToTwoDecimals,
  getValidDate,
} from '../../services/data';
import AlertaGeneral from '../Alerts/AlertaGeneral';
import { ReactComponent as Copy } from '../../assets/images/copy.svg';

const Modal = ({ children }) => {
  return createPortal(
    <div className={`modal_container dco-details-transaccions-modal-container`}>
      {children}
    </div>,
    document.getElementById('root'),
  );
};
const useErroresExcepciones = ({ erroresArray }) => {
  const [erroresCount, setErroresCount] = useState(false);
  useEffect(() => {
    let conteo = {};
    if (erroresArray?.length > 0) {
      for (const error of erroresArray) {
        if (conteo[error.toString()]) conteo[error.toString()]++;
        else conteo[error.toString()] = 1;
      }
      let salida = [];
      for (const key in conteo) {
        if (Object.prototype.hasOwnProperty.call(conteo, key)) {
          const element = conteo[key];
          salida.push({ name: key, count: element });
        }
      }
      setErroresCount(salida);
    } else setErroresCount(false);
  }, [erroresArray]);
  return erroresCount;
};
export default function Details({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleCopy, copied, showCopied } = useCopy();

  const Error_excepcion_Transaccion = useErroresExcepciones({
    erroresArray: data?.Error_excepcion_Transaccion,
  });
  return (
    <>
      {isOpen && (
        <Modal>
          <div
            data-testid={`modal-details-${data.idTransaccion}`}
            className='transactions_modal_details position-relative'
          >
            <div
              style={{ marginBottom: '23px' }}
              className='container-fluid p-0 d-flex flex-wrap flex-md-nowrap details_transactions_header_container'
            >
              <PageHeader type='modal'>
                <PageTitle>Detalle de transacción</PageTitle>
                <PageSubtitle>
                  Ve el detalle de la transacción elegida
                </PageSubtitle>
              </PageHeader>
            </div>
            <div
              style={{ marginBottom: '14px' }}
              className='container-fluid p-0 d-flex flex-column shadow'
            >
              <div
                style={{
                  background: 'var(--secondary-color)',
                  padding: '13px 29px 13px 16px',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                }}
                className='container-fluid d-flex justify-content-between'
              >
                <p
                  style={{
                    fontSize: '10px',
                    color: 'var(--secondary-text-color)',
                    fontWeight: '700',
                  }}
                  className='m-0'
                >
                  Resultado de la transacción
                </p>
              </div>
              <div
                className='bg-white container-fluid d-flex flex-wrap justify-content-between gap-1'
                style={{ padding: '13px 12px' }}
              >
                <div className='row p-0 m-0 container'>
                  <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6 flex-wrap flex-md-nowrap'>
                    <p
                      data-testid={`label-details-${data.idTransaccion}`}
                      className='m-0 p-0 col-6'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'ID Transacción:'}
                    </p>
                    <div className='m-0 p-0 col-6'>
                      <CopyCell
                        padding={'0px'}
                        text={data.idTransaccion ?? ''}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                    <p
                      className='m-0 p-0 col-6'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'Hash del CURP:'}
                    </p>
                    <div className='m-0 p-0 col-6'>
                      <CopyCell padding={'0px'} text={data.curp_hash ?? ''} />
                    </div>
                  </div>
                </div>
                <div className='row p-0 m-0 container'>
                  <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                    <p
                      className='m-0 p-0 col-6'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'Fecha/Hora:'}
                    </p>
                    <p
                      className='col-6 m-0'
                      style={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {getValidDate(data, 'fecha_alta')}
                    </p>
                  </div>

                  <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                    <p
                      className='m-0 p-0 col-6'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'Tiempo de respuesta:'}
                    </p>
                    <p
                      className='col-6 m-0'
                      style={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data.tiempos_servicios?.instruccion_validar_embeddings ??
                        ''}
                      {' seg'}
                    </p>
                  </div>
                </div>
                <div className='row p-0 m-0 container'>
                  <div className='d-flex justify-content-start container row m-0 p-0 col-12 col-md-6'>
                    <p
                      className='m-0 p-0 col-6'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'Validación:'}
                    </p>
                    <p
                      className='col-4 m-0'
                      style={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <ValidationLabel validation={data?.validacion ?? 'N/A'} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {data?.datos_registro &&
              Object.keys(data.datos_registro).length > 0 && (
                <div className='container-fluid p-0 d-flex flex-column shadow'>
                  <div
                    style={{
                      background: 'var(--secondary-color)',
                      padding: '13px 29px 13px 16px',
                      borderTopLeftRadius: '4px',
                      borderTopRightRadius: '4px',
                    }}
                    className='container-fluid d-flex justify-content-between'
                  >
                    <p
                      style={{
                        fontSize: '10px',
                        color: 'var(--secondary-text-color)',
                        fontWeight: '700',
                      }}
                      className='m-0'
                    >
                      Datos del registro
                    </p>
                  </div>
                  <div
                    className='bg-white container-fluid d-flex flex-wrap justify-content-between gap-1'
                    style={{ padding: '13px 12px' }}
                  >
                    <div className='row p-0 m-0 container'>
                      <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                        <p
                          className='m-0 p-0 col-6'
                          style={{ fontSize: '10px', fontWeight: '700' }}
                        >
                          {'Fecha/Hora carga:'}
                        </p>
                        <p
                          className='m-0 col-6'
                          style={{
                            fontSize: '10px',
                            overflow: 'hidden',
                            paddingLeft: '0px',
                            paddingRight: '12px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {getValidDate(data, 'datos_registro.fecha_alta')}
                        </p>
                      </div>
                      <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                        <p
                          className='m-0 p-0 col-6'
                          style={{ fontSize: '10px', fontWeight: '700' }}
                        >
                          {'Índice calidad registro:'}
                        </p>
                        <p
                          className='col-6 m-0'
                          style={{
                            fontSize: '10px',
                            overflow: 'hidden',
                            paddingLeft: '0px',
                            paddingRight: '12px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {data.datos_registro?.quality_score
                            ? data.datos_registro?.quality_score
                            : '- -'}
                        </p>
                      </div>
                    </div>

                    <div className='row p-0 m-0 container'>
                      <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                        <p
                          className='m-0 p-0 col-6'
                          style={{ fontSize: '10px', fontWeight: '700' }}
                        >
                          {'Folio validación CURP:'}
                        </p>
                        <p
                          className='m-0 col-6 d-flex gap-2 align-items-center'
                          style={{
                            fontSize: '10px',
                            overflow: 'hidden',
                            paddingLeft: '0px',
                            paddingRight: '12px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {data.datos_registro?.folio_validacion_renapo ? (
                            <>
                              Sí
                              <button
                                onClick={() =>
                                  handleCopy(
                                    data.datos_registro
                                      ?.folio_validacion_renapo,
                                  )
                                }
                                className='copy_button d-flex'
                              >
                                <Copy />
                              </button>
                            </>
                          ) : (
                            'No'
                          )}
                        </p>
                      </div>
                      <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                        <p
                          className='m-0 p-0 col-6'
                          style={{ fontSize: '10px', fontWeight: '700' }}
                        >
                          {'Fecha validación CURP:'}
                        </p>
                        <p
                          className='col-6 m-0'
                          style={{
                            fontSize: '10px',
                            overflow: 'hidden',
                            paddingLeft: '0px',
                            paddingRight: '12px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {getValidDate(
                            data,
                            'datos_registro.fecha_validacion_renapo',
                          )}
                        </p>
                      </div>
                    </div>
                    <div className='row p-0 m-0 container'>
                      <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                        <p
                          className='m-0 p-0 col-6'
                          style={{ fontSize: '10px', fontWeight: '700' }}
                        >
                          {'Folio validación INE:'}
                        </p>
                        <p
                          className='m-0 col-6 d-flex gap-2 align-items-center'
                          style={{
                            fontSize: '10px',
                            overflow: 'hidden',
                            paddingLeft: '0px',
                            paddingRight: '12px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {data.datos_registro?.folio_validacion_ine ? (
                            <>
                              Sí
                              <button
                                onClick={() =>
                                  handleCopy(
                                    data.datos_registro?.folio_validacion_ine,
                                  )
                                }
                                className='copy_button d-flex'
                              >
                                <Copy />
                              </button>
                            </>
                          ) : (
                            'No'
                          )}
                        </p>
                      </div>
                      <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                        <p
                          className='m-0 p-0 col-6'
                          style={{ fontSize: '10px', fontWeight: '700' }}
                        >
                          {'Fecha validación INE:'}
                        </p>
                        <p
                          className='col-6 m-0'
                          style={{
                            fontSize: '10px',
                            overflow: 'hidden',
                            paddingLeft: '0px',
                            paddingRight: '12px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {getValidDate(
                            data,
                            'datos_registro.fecha_validacion_ine',
                          )}
                        </p>
                      </div>
                    </div>
                    <div className='row p-0 m-0 container'>
                      <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                        <p
                          className='m-0 p-0 col-6'
                          style={{ fontSize: '10px', fontWeight: '700' }}
                        >
                          {'Índice calidad de fotografía:'}
                        </p>
                        <p
                          className='m-0 col-6'
                          style={{
                            fontSize: '10px',
                            overflow: 'hidden',
                            paddingLeft: '0px',
                            paddingRight: '12px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {data?.foto_calidad_promedio
                            ? formatToTwoDecimals(data?.foto_calidad_promedio)
                            : formatToTwoDecimals('0')}
                        </p>
                      </div>
                      <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                        <p
                          className='m-0 p-0 col-6'
                          style={{ fontSize: '10px', fontWeight: '700' }}
                        >
                          {'Antigüedad de la fotografía:'}
                        </p>
                        <p
                          className='col-6 m-0'
                          style={{
                            fontSize: '10px',
                            overflow: 'hidden',
                            paddingLeft: '0px',
                            paddingRight: '12px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {getValidDate(data, 'datos_registro.fecha_foto')}
                        </p>
                      </div>
                    </div>
                    <div className='row p-0 m-0 container'>
                      <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                        <p
                          className='m-0 p-0 col-6'
                          style={{ fontSize: '10px', fontWeight: '700' }}
                        >
                          {'Antigüedad del registro:'}
                        </p>
                        <p
                          className='m-0 col-6'
                          style={{
                            fontSize: '10px',
                            overflow: 'hidden',
                            paddingLeft: '0px',
                            paddingRight: '12px',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {getValidDate(data, 'datos_registro.fecha_registro')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div className='container-fluid p-0 d-flex flex-column shadow'>
              <div
                style={{
                  background: 'var(--secondary-color)',
                  padding: '13px 29px 13px 16px',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                }}
                className='container-fluid d-flex justify-content-between'
              >
                <p
                  style={{
                    fontSize: '10px',
                    color: 'var(--secondary-text-color)',
                    fontWeight: '700',
                  }}
                  className='m-0'
                >
                  Nodos
                </p>
              </div>
              <div
                className='bg-white container-fluid d-flex flex-wrap justify-content-between gap-1'
                style={{ padding: '13px 12px' }}
              >
                <div className='row p-0 m-0 container'>
                  <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                    <p
                      className='m-0 p-0 col-7'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'Considerados en validación:'}
                    </p>
                    <p
                      className='m-0 col-5'
                      style={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data.Nodos_Considerados_Validacion ?? ''}
                    </p>
                  </div>
                  <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                    <p
                      className='m-0 p-0 col-7'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'Existe registro:'}
                    </p>
                    <p
                      className='col-5 m-0'
                      style={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data?.Nodos_Existe_Registro ?? ''}
                    </p>
                  </div>
                </div>
                <div className='row p-0 m-0 container'>
                  <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                    <p
                      className='m-0 p-0 col-7'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'Fuera de tiempo:'}
                    </p>
                    <p
                      className='m-0 col-5'
                      style={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data.Nodos_Fuera_de_Tiempo ?? ''}
                    </p>
                  </div>
                  <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                    <p
                      className='m-0 p-0 col-7'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'Error en validación:'}
                    </p>
                    <p
                      className='col-5 m-0'
                      style={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data?.Nodos_Error_Validacion ?? ''}
                    </p>
                  </div>
                </div>
                <div className='row p-0 m-0 container'>
                  <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                    <p
                      className='m-0 p-0 col-7'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'Considerados para Val ID Score:'}
                    </p>
                    <p
                      className='m-0 col-5'
                      style={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data.Nodos_Considerados_ValIDScore ?? ''}
                    </p>
                  </div>
                  <div className='d-flex justify-content-between container row m-0 p-0 col-12 col-md-6'>
                    <p
                      className='m-0 p-0 col-7'
                      style={{ fontSize: '10px', fontWeight: '700' }}
                    >
                      {'No disponibles:'}
                    </p>
                    <p
                      className='col-5 m-0'
                      style={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data?.Nodos_No_Disponibles ?? ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='container-fluid p-0 d-flex flex-column shadow'>
              <div
                style={{
                  background: 'var(--secondary-color)',
                  padding: '13px 29px 13px 16px',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                }}
                className='container-fluid d-flex justify-content-between'
              >
                <p
                  style={{
                    fontSize: '10px',
                    color: 'var(--secondary-text-color)',
                    fontWeight: '700',
                  }}
                  className='m-0'
                >
                  Notas de la transacción
                </p>
              </div>
              <div
                className='bg-white container-fluid d-flex flex-wrap justify-content-between gap-1'
                style={{ padding: '13px 12px' }}
              >
                <ul className='row container mb-0'>
                  {Error_excepcion_Transaccion?.length > 0 ? (
                    <>
                      {Error_excepcion_Transaccion.map((element) => (
                        <li
                          style={{
                            fontSize: '10px',
                          }}
                          key={element.name}
                          className='col-12'
                        >
                          {`${element.name} (${element.count} ${
                            element.count > 1 ? 'nodos' : 'nodo'
                          })`}
                        </li>
                      ))}
                    </>
                  ) : (
                    <p
                      className='m-0'
                      style={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        paddingLeft: '0px',
                        paddingRight: '12px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      Sin errores o excepciones.
                    </p>
                  )}
                </ul>
              </div>
            </div>
            <button
              onClick={() => setIsOpen(false)}
              type='button'
              className='button button_active'
              style={{ marginTop: '15px' }}
            >
              Cerrar
            </button>
          </div>
        </Modal>
      )}
      <button
        data-testid={`button-details-${data.idTransaccion}`}
        style={{ fontSize: '9px' }}
        className='management_see_details_button'
        onClick={() => setIsOpen(true)}
        type='button'
      >
        Ver detalle
      </button>
      {loading && <Loader />}
      {showCopied && (
        <AlertaGeneral type={copied ? 'success' : 'error'}>
          {copied
            ? 'Hemos copiado el dato a tu portapapeles.'
            : 'Lo sentimos, no hemos copiado el dato a tu portapapeles.'}
        </AlertaGeneral>
      )}
    </>
  );
}
