import React from "react";
export default function TituloPrincipal({ titulo, subtitulo, color, number }) {
  return (
    <>
      <div className="d-flex flex-column">
        <p className="dco_graficas-title m-0" style={color ? { color } : {}}>
          {titulo}
          {number &&
            <b>
              {number.toLocaleString()}
            </b>
          }
        </p>
        <p className="dco_graficas-subtitle">
          {subtitulo}
        </p>
      </div>
    </>
  );
}
