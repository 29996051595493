import React from 'react';

const validationMap = {
  'Sin Coincidencia': {
    bg: '#FFBB00',
    border:  '#FFBB00',
    color: 'white',
    text: 'No exitosa',
  },
  Coincidencia: {
    bg: '#609571',
    border:  '#609571',
    color: 'white',
    text: 'Exitosa',
  },
  'No existe': {
    bg: '#7F7F7FCC',
    border:  '#7F7F7FCC',
    color: 'white',
    text: 'Sin registro',
  },
  "N/A": {
    bg: 'transparent',
    border:  '#7F7F7FCC',
    color: '#7F7F7FCC',
    text: 'No iniciada',
  },

  'No exitosa': {
    bg: '#FFBB00',
    border:  '#FFBB00',
    color: 'white',
    text: 'No exitosa',
  },
  Exitosa: {
    bg: '#609571',
    border:  '#609571',
    color: 'white',
    text: 'Exitosa',
  },
  'Sin registro': {
    bg: '#7F7F7FCC',
    border:  '#7F7F7FCC',
    color: 'white',
    text: 'Sin registro',
  },
  "No iniciada": {
    bg: 'transparent',
    border:  '#7F7F7FCC',
    color: '#7F7F7FCC',
    text: 'No iniciada',
  },
};

export default function ValidationLabel({ validation }) {
  return (
    <div
      className='d-flex rounded-pill text-nowrap align-items-center justify-content-center'
      style={{
        color:
          validationMap[validation]?.color,
        backgroundColor:
          validationMap[validation]?.bg,
        borderColor:
          validationMap[validation]?.border,
          borderWidth: "1px",
          borderStyle: "solid",
        padding: "4px",
        fontSize: "9px"
      }}
    >
      {validationMap[validation]?.text}
    </div>
  );
}
