import React, { useState, useEffect, useCallback } from "react";
import InputSelect from "../Inputs/InputSelect";
import InputNewPassword from "../Inputs/InputNewPassword";
import SelectorAvatar from "./SelectorAvatar";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  completeRegistro,
  getCatalogoAreas,
  getCatalogoPosicion,
  validateEmailLogin,
  login,
} from "../../services/api";
import ToSkeleton from "../Skeleton/ToSkeleton";

import { TIME_ALERT, useLogo, useMultipleErrorReducer } from "../../services/data.js";
import DatePickerInput from "../Inputs/DatePickerInput";
import { Permisos } from "../../services/permisos.js";
import { availableModulesCatalog } from "../Login/LoginComponent";

const CompletarRegistroForm = () => {
  const [userName, setUserName] = useState("");
  const [area, setArea] = useState("");
  const [puesto, setPuesto] = useState("");
  const [password, setPassword] = useState("");
  const [banderaPassword, setBanderaPassword] = useState("");
  const [selfie, setSelfie] = useState(null);
  const [fecha, setFecha] = useState("");
  const [areaCatalogo, setAreaCatalogo] = useState([]);
  const [posicionCatalogo, setPosicionCatalogo] = useState([]);
  const [positionSelectedCatalog, setPositionSelectedCatalog] = useState([]);
  const [userID, setUserID] = useState("");
  const [emailConfirmationToken, setEmailConfirmationToken] = useState("");
  const [tokenReset, setTokenReset] = useState("");
  const [extensionFile, setExtensionFile] = useState("png");
  const [infoUser, setInfoUser] = useState([]);
  const [error, setError] = useState(true);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState("");
  const { errorValues, addError, handleError } = useMultipleErrorReducer();
  const logo = useLogo();
  const [logoDimensions, setLogoDimensions] = useState({ with: "auto", height: "auto" });

  const navigate = useNavigate();

  useEffect(() => {
    const styleCustom = JSON.parse(sessionStorage.getItem("enviromentConfig"))?.REACT_APP_VIEW_CONFIG ?? null;
    if ( styleCustom ) {
      setLogoDimensions({ with: styleCustom?.dimensiones?.logo_complete_register?.w, height: styleCustom?.dimensiones?.logo_complete_register?.h })
    }
    obtenerDatosUrl();
  }, []);

  const getAllData = useCallback(
    async (email) => {
      if (!navigator.onLine) {
        addError(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        handleError();
        return;
      }
      setLoading(true);
      const [promisseUserInfo, promisseArea, promissePosition] =
        await Promise.allSettled([
          validateEmailLogin(email, true),
          getCatalogoAreas(),
          getCatalogoPosicion(),
        ]);
      if (promisseUserInfo.status === "rejected") {
        addError("Ocurrió un error al consultar la información del usuario");
      }
      if (promisseArea.status === "rejected") {
        addError("Ocurrió un error al consultar el catálogo de áreas");
      }

      if (promissePosition.status === "rejected") {
        addError("Ocurrió un error al consultar el catálogo de posiciones");
      }
      if (
        promisseUserInfo.status === "rejected" ||
        promisseArea.status === "rejected" ||
        promissePosition.status === "rejected"
      ) {
        handleError();
        setLoading(false);
      }
      if (
        promisseUserInfo.status === "fulfilled" &&
        !promisseUserInfo?.value?.success
      ) {
        addError(
          promisseUserInfo?.value?.error_message ??
            "Lo sentimos ocurrió un error"
        );
        handleError();
        return;
      }
      const areaJson = await promisseArea.value.json();
      const positionJson = await promissePosition.value.json();
      if (
        promisseUserInfo.status === "fulfilled" &&
        promisseUserInfo?.value?.success
      ) {
        setInfoUser(promisseUserInfo?.value?.data ?? {});
        setUserName(promisseUserInfo?.value?.data.firstName);
      }
      if (promisseArea.status === "fulfilled" && areaJson?.isSucceed) {
        setAreaCatalogo(areaJson?.data ?? []);
      }
      if (promissePosition.status === "fulfilled" && positionJson?.isSucceed) {
        setPosicionCatalogo(positionJson?.data ?? []);
      }
      if (
        promisseUserInfo.status === "fulfilled" &&
        promisseUserInfo?.value?.success &&
        promisseArea.status === "fulfilled" &&
        areaJson?.isSucceed &&
        promissePosition.status === "fulfilled" &&
        positionJson?.isSucceed
      ) {
        setError(false);
      }
      setLoading(false);
    },
    [addError, handleError]
  );

  useEffect(() => {
    if (area) {
      const selectedArea = posicionCatalogo.filter(
        (areaC) => areaC.areaId === area.id
      );
      setPositionSelectedCatalog(selectedArea ?? []);
    }
  }, [area]);

  const validarDatos = () => {
    return (
      area &&
      puesto &&
      fecha &&
      selfie &&
      password &&
      (banderaPassword === "strong" || banderaPassword === "green") &&
      isValid
    );
  };

  const obtenerDatosUrl = async () => {
    try {
      let queryString = window.location.search.replaceAll("+", "%2B");
      let urlParams = new URLSearchParams(queryString);
      let userID = urlParams.get("UserID");
      let passwordResetToken = urlParams.get("PasswordResetToken");
      let emailConfirmationToken = urlParams.get("EmailConfirmationToken");
      let email = urlParams.get("Email");
      if (!(userID && passwordResetToken && emailConfirmationToken)) {
        addError("Parámetros de URL inválidos.");
        handleError();
      }
      setUserID(userID);
      setTokenReset(passwordResetToken);
      setEmailConfirmationToken(emailConfirmationToken);
      getAllData(email);
      setEmail(email);
    } catch (error) {
      console.log(error);
    }
  };

  const completarRegistro = async () => {
    try {
      setLoading(true);
      let regRes = await completeRegistro(
        userID,
        emailConfirmationToken,
        tokenReset,
        password,
        selfie,
        area.id,
        puesto.id,
        fecha
      );
      let resJson = await regRes.json();
      if (regRes.ok && resJson.isSucceed) {
        setSuccess("Registro Completado Correctamente");
        autoLogin();
      } else {
        if (resJson.messages?.password) {
          addError(
            "La contraseña no debe incluir datos personales (Nombre o Apellidos)"
          );
          handleError();

          return;
        } else if (resJson.messages?.validations)
          if (resJson.messages?.validations?.length > 0)
            addError(resJson.messages?.validations[0]);
          else addError(resJson.messages?.validations);
        else addError("Problemas al terminar su registro");
        handleError();
      }
    } catch (error) {
      if (error.toString().includes("Formato Fecha")) {
        addError("Fecha de nacimiento no válida");
        handleError();
      } else {
        console.log(error);
        addError("Problemas al completar su registro.");
        handleError();
      }
    } finally {
      setLoading(false);
      setTimeout(() => {
        setSuccess("");
      }, TIME_ALERT);
    }
  };

  const autoLogin = async () => {
    try {
      let data = await validateEmailLogin(email);
      if (data.success) {
        sessionStorage.setItem(
          "lastPasswordChangedDate",
          data.data.lastPasswordChangedDate
        );
      } else {
        addError("Estamos teniendo problemas con el Inicio de Sesion");
        handleError();

        return;
      }
      const response = await login({
        email: infoUser.email,
        password: password,
      });
      if (!response.success) {
        addError("Estamos teniendo problemas con el Inicio de Sesion");
        handleError();

        return;
      } else {
        sessionStorage.setItem("tokenType", response.data.tokenType);
        sessionStorage.setItem("accessToken", response.data.accessToken);
        sessionStorage.setItem("expiresIn", response.data.expiresIn);
        sessionStorage.setItem("refreshToken", response.data.refreshToken);
        sessionStorage.setItem("userEmail", infoUser.email);
        await inicializarPeticionPermisos();
        const availableModules = Permisos.obtenerModulosDisponibles();
        if (availableModules.includes("Dashboard")) {
          navigate("/dashboard");
          return;
        }
        if (availableModules.includes("Usuario")) {
          navigate("/usuarios");
          return;
        }
        navigate(availableModulesCatalog[availableModules[0]]);
        return;
      }
    } catch (error) {
      addError("Problemas al iniciar Sesion");
      handleError();
    }
  };
  const inicializarPeticionPermisos = () => {
    let instancia = Permisos.getInstance();

    let prom = new Promise((resolver, rechazar) => {
      if (!instancia.datosCargados)
        instancia.updateDataFunc = (data) => {
          if (data.OK) resolver();
          else {
            rechazar("Error al obtener Privilegios");
          }
        };
      else resolver();
    });
    return prom;
  };
  const handleDatePickerChange = (newValue) => {
    const { value } = newValue;
    const valueSet =
      dayjs(value).format("YYYY-MM-DD") !== "Invalid Date"
        ? dayjs(value).format("YYYY-MM-DD")
        : "";
    setFecha(valueSet);
  };

  const formatoFecha = (date) => {
    try {
      let arrayFecha = date.split("/");
      let fechaFormat =
        (arrayFecha[2].length > 1 ? arrayFecha[2] : arrayFecha[2]) +
        "-" +
        (arrayFecha[0].length > 1 ? arrayFecha[0] : "0" + arrayFecha[0]) +
        "-" +
        (arrayFecha[1].length > 1 ? arrayFecha[1] : "0" + arrayFecha[1]);
      return fechaFormat;
    } catch (error) {
      console.log(error);
      throw new Error("Formato Fecha");
    }
  };

  useEffect(() => {
    if (fecha !== "") {
      if (dayjs(fecha).format("YYYY-MM-DD") !== "Invalid Date") {
        const maxAge = dayjs(fecha).isBefore(dayjs().subtract(120, "year"));
        const minAge = dayjs(fecha).isAfter(dayjs().subtract(18, "year"));
        const afterToday = dayjs(fecha).isAfter(dayjs());
        let arrayMessages = [];

        if (afterToday) {
          arrayMessages.push(
            "La fecha de nacimiento ingresada no puede ser igual o mayor a la fecha actual"
          );
        }
        if (maxAge && !afterToday && !minAge) {
          arrayMessages.push(
            "La fecha de nacimiento ingresada no puede ser mayor a 120 años"
          );
        }
        if (minAge && !afterToday && !maxAge) {
          arrayMessages.push(
            "La fecha de nacimiento ingresada no puede ser de un menor de edad"
          );
        }
        if (afterToday || maxAge || minAge) {
          addError(arrayMessages.join("\n"));
          handleError();

          setIsValid(false);
          return;
        }
        setIsValid(true);
      }
    }
  }, [fecha]);

  return (
    <>
      <div className="row m-0" style={{ minWidth: "100" }}>
        <div className="w-100 complete_register_container_template p-0">
          <img
            src={logo}
            alt="DICIO  Alliance logo"
            className="w-100 h-auto"
            {...logoDimensions}
            style={{ maxWidth: `${logoDimensions.with}px`, maxHeight: `${logoDimensions.height}px` }}
          />
          <div className="d-flex flex-column align-items-start w-100">
            <ToSkeleton loading={error}>
              <p
                className="fw-bold"
                style={{ marginBottom: "24px", lineHeight: "20.8px" }}
              >
                Hola {userName}, captura los siguientes datos para activar tu
                cuenta
              </p>
            </ToSkeleton>

            <div
              className="d-flex flex-column align-items-center justify-content-center w-100"
              style={{ gap: "7px" }}
            >
              <div
                className="container d-flex flex-column align-items-start p-0"
                style={{ marginBottom: "0px" }}
              >
                <ToSkeleton loading={error}>
                  <p
                    className="input_label_small"
                    style={{ marginBottom: "6.8px", paddingLeft: "10px" }}
                  >
                    Selecciona un Avatar o sube tu fotografía
                  </p>
                </ToSkeleton>

                <ToSkeleton loading={error}>
                  <SelectorAvatar
                    setFlieProp={setSelfie}
                    setExtensionFileProp={setExtensionFile}
                    value={selfie}
                    newUser={true}
                  />
                </ToSkeleton>
              </div>
              <div className="mt-0 container-fluid p-0 position-relative complete_register_date">
                <ToSkeleton loading={error}>
                  <DatePickerInput
                    onChange={handleDatePickerChange}
                    value={fecha}
                    label="Fecha de Nacimiento"
                    id="birdth_date"
                    valid={true}
                    rounded={false}
                    maxDate={dayjs().subtract(1, "day")}
                    required={true}
                  />
                </ToSkeleton>
              </div>
              <div className="complete_register_input">
                <ToSkeleton loading={error}>
                  <InputSelect
                    labelProp="Área"
                    id="area"
                    setValueProp={(value) => {
                      setPuesto("");
                      setArea(value);
                    }}
                    options={areaCatalogo}
                    required
                  />
                </ToSkeleton>
                <ToSkeleton loading={error}>
                  <InputSelect
                    labelProp="Puesto"
                    id="position"
                    setValueProp={setPuesto}
                    options={positionSelectedCatalog}
                    required
                  />
                </ToSkeleton>
                <ToSkeleton loading={error}>
                  <InputNewPassword
                    setBanderaProp={setBanderaPassword}
                    setPasswordProp={setPassword}
                    id={"new_password"}
                  />
                </ToSkeleton>
              </div>
              <div
                className="container p-0 flex justify-content-start"
                style={{ marginTop: "34px", marginBottom: "0px" }}
              >
                <button
                  className={`button ${
                    validarDatos() && !loading
                      ? "button_active"
                      : "button_disabled"
                  }`}
                  disabled={!validarDatos() || loading}
                  onClick={completarRegistro}
                  data-testid="active_account_button"
                >
                  Activar cuenta
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errorValues.show && (
        <AlertaGeneral
          type={"error"}
          {...(errorValues.errors.length > 1
            ? { errors: errorValues.errors }
            : {})}
        >
          {errorValues.errors.length > 1
            ? errorValues.text
            : errorValues.errors[0]}
        </AlertaGeneral>
      )}
      {success && <AlertaGeneral type="success">{success}</AlertaGeneral>}
      {loading && <Loader />}
    </>
  );
};

export default CompletarRegistroForm;
