import React, {
  useReducer,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import Checkbox from "../Inputs/Checkbox";
import Input from "../Inputs/Input";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import { getAllPrivilegesService, createRolService } from "../../services/api";
import { handleGetPrivileges, TIME_ALERT } from "../../services/data";
import { UserContext } from "./Body";
import { v4 as uuidv4 } from "uuid";
import ToSkeleton from "../Skeleton/ToSkeleton";

const useUserContext = () => useContext(UserContext);

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "TOGGLE_FIELD":
      return {
        ...state,
        [action.field]: !state[action.field],
      };
    default:
      return state;
  }
};

const useRolForm = ({ handleModal }) => {
  const [values, dispatch] = useReducer(formReducer, { name: "" });
  const [privileges, setPrivileges] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);
  const { handleRefreshRol } = useUserContext();

  useEffect(() => {
    handleGetPrivileges({
      service: getAllPrivilegesService,
      setter: setPrivileges,
      error_message: setError,
      setLoading,
    });
  }, []);

  const getPrivilegesArray = () => {
    return Object.keys(values).filter(
      (key) => key !== "name" && values[key] === true
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const privilegesIds = getPrivilegesArray();
    if (values.name === "") {
      setError("Por favor ingrese el nombre del rol");
      setTimeout(() => {
        setError(null);
      }, TIME_ALERT);
      return;
    }
    if (privilegesIds.length === 0) {
      setError("Por favor elija al menos un privilegio");
      setTimeout(() => {
        setError(null);
      }, TIME_ALERT);
      return;
    }
    try {
      setLoading(true);
      const response = await createRolService({
        name: values.name,
        privileges: getPrivilegesArray(),
      });
      if (!response.success) {
        setLoading(false);
        console.error(response.message);
        setError(response.message);
        setTimeout(() => {
          setError(null);
        }, TIME_ALERT);
        return;
      }
      setLoading(false);
      handleRefreshRol();
      setSuccess(response.message);
      setTimeout(() => {
        setSuccess(null);
        handleModal();
      }, TIME_ALERT);
      return;
    } catch (error) {
      setLoading(false);
      setError("Error al obtener los privilegios");
      setTimeout(() => {
        setError(null);
      }, TIME_ALERT);
      return;
    }
  };

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const setToggle = (field) => {
    dispatch({ type: "TOGGLE_FIELD", field });
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    setToggle(name);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const privilegesInputs = useMemo(
    () =>
      Object.keys(privileges).map((key) => (
        <div
          key={uuidv4()}
          style={{
            height: "fit-content",
            margin: "0 0 20px 0",
          }}
          className="col-12 col-md-5 p-0 row"
        >
          <div className="col-12" style={{ marginBottom: "25px" }}>
            <p style={{ fontSize: "18px" }} className="fw-bold card_text  m-0">
              {key}
            </p>
          </div>
          {privileges[key].map(({ id, action, description }) => (
            <div className="col-11 offset-1">
              <Checkbox
                key={`${uuidv4()}_${id}${action}`}
                bold={true}
                onChange={handleCheckbox}
                id={id}
                name={id}
                label={`${action}`}
                value={values[id]}
              />
            </div>
          ))}
        </div>
      )),
    [privileges, values]
  );

  return {
    handleSubmit,
    handleChange,
    values,
    error,
    success,
    loading,
    privilegesInputs,
    privileges,
  };
};

export default function RolForm({ handleModal }) {
  const {
    handleSubmit,
    handleChange,
    values,
    error,
    success,
    loading,
    privilegesInputs,
    privileges,
  } = useRolForm({ handleModal });
  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ gap: "44px" }}
        className={"container-fluid d-flex flex-column align-items-start p-0"}
      >
        <Input
          id="name"
          name="name"
          type="text"
          label="Nombre del rol"
          valid={true}
          value={values.name}
          rounded={false}
          onChange={handleChange}
        />
        <div style={{ columnGap: "59px" }} className="row m-0 p-0 container">
          {privilegesInputs}
          {privileges.length === 0 && (
            <>
              <ToSkeleton
                loading={true}
                style={{ minWidth: "100%", minHeight: "54px" }}
              ></ToSkeleton>
              <ToSkeleton
                loading={true}
                style={{ minWidth: "100%", minHeight: "54px" }}
              ></ToSkeleton>
              <ToSkeleton
                loading={true}
                style={{ minWidth: "100%", minHeight: "54px" }}
              ></ToSkeleton>
              <ToSkeleton
                loading={true}
                style={{ minWidth: "100%", minHeight: "54px" }}
              ></ToSkeleton>
              <ToSkeleton
                loading={true}
                style={{ minWidth: "100%", minHeight: "54px" }}
              ></ToSkeleton>
              <ToSkeleton
                loading={true}
                style={{ minWidth: "100%", minHeight: "54px" }}
              ></ToSkeleton>
              <ToSkeleton
                loading={true}
                style={{ minWidth: "100%", minHeight: "54px" }}
              ></ToSkeleton>
              <ToSkeleton
                loading={true}
                style={{ minWidth: "100%", minHeight: "54px" }}
              ></ToSkeleton>
            </>
          )}
        </div>
        <div style={{ paddingTop: "26px" }} className="d-flex gap-3">
          <button data-testid="save_rol_button" type="submit" className={"button button_active"}>
            Guardar rol
          </button>
          <button
            data-testid="save_cancel_rol_button"
            type="button"
            className={"cancel_button"}
            onClick={handleModal}
          >
            Cancelar
          </button>
        </div>
      </form>
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{error}</AlertaGeneral>}
      {success && <AlertaGeneral type={"success"}>{success}</AlertaGeneral>}
    </>
  );
}
