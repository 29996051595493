/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import TituloPrincipal from "../Dashboard/TituloPrincipal";
import c3 from "c3";
import "../../assets/c3-0.7.20/c3.min.css";
import "c3/c3.css";
import dayjs from "dayjs";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

export default function GraficaTiempoPromedio({
  titulo = "Tiempo de respuesta promedio del nodo: ",
  resumenNumero = "0",
  minHeight,
  widthGeneral,
  heightGeneral,
  axis,
  grid,
  legend,
  tooltip,
  subchart,
  zoom,
  color,
  size,
  padding,
  point,
  regions,
  transition,
  title,
  data = {},
  flexFlow,
  getIdDivGraphic,
  paddingRight,
  onOptionsClick = () => { },
  ...rest
}) {
  const chartRef = useRef(null);
  const windowWidth = useWindowWidth();
  //const uniqueId = `chart_${uuidv4().replace(/-/g, "")}`;
  const uniqueId = `chart_tiempo_promedio`;

  useEffect(() => {
    getIdDivGraphic(uniqueId, 'tiempoPromedio')
  }, [])

  useEffect(() => {
    const colorCustom = JSON.parse(sessionStorage.getItem("enviromentConfig"))?.REACT_APP_VIEW_CONFIG?.colors ?? null;
    const pattern = colorCustom?.paletaGraficas ?? ["#6236FF", "#22135E", "#6236FF", "#22135E"];
    if (chartRef.current) {
      let indicesX = ["x"];
      let indicesY = ["Segundos"];
      let valuesData = data;

      for (let index = 1; index <= valuesData.length; index++) {
        indicesX.push(index);
        indicesY.push(valuesData[index - 1]?.count);
      }
      const chart = c3.generate({
        bindto: `#${uniqueId}`,
        data: {
          xs: {
            Segundos: "x",
          },
          columns: [indicesX, indicesY],
          type: 'bar',
        },
        bar: {
          width: 20
        },
        color: {
          pattern,
        },
        grid: {
          y: {
            show: true,
            className: 'grid_lines_charts'
          }
        },
        axis: {
          y: {
            label: {
              text: 'Segundos',
              position: 'outer-middle'
            }
          },
          x: {
            label: {
              text: 'Horas',
              position: 'outer-center'
            },
            type: 'category',
            tick: {
              format: function (x) {
                return `${(x+1)?.toString().padStart(2, '0')}`
              },
              count: data.length > 10 ? 10 : data.length,
              fit: true ,
            }
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          contents: function (e) {
            return `<div class="tooltip_charts_dashboard">${e[0].value} seg ${dayjs(data[e[0].index].inicio).format('DD/MM/YYYY HH:mm')} - ${dayjs(data[e[0].index].fin).format('DD/MM/YYYY HH:mm')}</div>`;
          }
        },
        subchart: subchart,
        zoom: zoom,
        size: size,
        padding: {
          right: paddingRight
        },
        point: point,
        regions: regions,
        transition: transition,
        title: title,
        ...rest,
      });

      return () => {
        chart.destroy();
      };
    }
  }, [
    data,
    axis,
    grid,
    legend,
    tooltip,
    subchart,
    zoom,
    color,
    size,
    padding,
    point,
    regions,
    transition,
    title,
    rest,
    paddingRight
  ]);

  return (
    <div
      className={"dashboard_cards dashboard_chart_cards"}
      style={{
        width: "100%",
        height: heightGeneral,
        ...(windowWidth >= 1280
          ? widthGeneral
            ? { width: widthGeneral }
            : {}
          : flexFlow
            ? { flex: flexFlow }
            : {}),
        ...(minHeight ? { minHeight: minHeight } : {}),
      }}
    >
      <div>
        <div className="d-flex flex-row mx-3 mb-4">
          <TituloPrincipal titulo={titulo} number={`${resumenNumero || 0} seg`} />
        </div>
      </div>

      <div id={uniqueId} ref={chartRef}></div>
    </div>
  );
}
