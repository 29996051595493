/* eslint-disable */
import React, { useEffect } from "react";
/* Librerías */
import { useNavigate } from "react-router-dom";
/* Componentes */
import { Permisos } from "../services/permisos";
import PageTitle from "../components/Pages/Title";
import Template from "../components/Nav/Template";
import PageHeader from "../components/Pages/Header";
import DashboardComponent from "../components/Dashboard/DashboardComponent";

const Dashboard = () => {
  Permisos.refrescarPrivilegios();
  const navigate = useNavigate();
  const cerrarSesion = () => {
    sessionStorage.clear();
    Permisos.destroy();
    navigate("/login");
  };

  useEffect(() => {
    if (!Permisos.verificarPermiso("Dashboard", "Ver la sección"))
      cerrarSesion();
  }, []);

  return (
    <Template overflowX="hidden">
      <PageHeader>
        <PageTitle style={{paddingLeft: "0px !important"}} >Dashboard</PageTitle>
      </PageHeader>
      <DashboardComponent />
    </Template>
  );
};

export default Dashboard;
