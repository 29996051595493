import React, { useEffect, useRef, useState } from "react";
import EmailValidation from "./EmailValidation";
import Tooltip from "./Tooltip";
const useRequired = ({ required = false, regex = false, value = "" }) => {
  const [messageTooltip, setMessageTooltip] = useState("");
  const [showTooltip, setShowTooltip] = useState("");
  const [validInput, setValidInput] = useState(true);
  const [valorInput, setValorInput] = useState("");
  const focusedStart = useRef(false);
  useEffect(() => {
    if (value) {
      setValorInput(value);
    }
  }, []);

  const handleFocusRequired = (focused) => {
    if (!required) return;
    if (!focusedStart.current && focused) {
      focusedStart.current = true;
    } else if (!valorInput && focusedStart.current && !focused) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
    }
  };
  const handleChangeValue = (value) => {
    if (!required) return;
    const valorInputCurrent = value?.target?.value;
    setValorInput(valorInputCurrent);
    if (regex?.length > 0) {
      for (const element of regex) {
        let reg = new RegExp(element.value);
        if (
          element.coincidencia
            ? !reg.test(valorInputCurrent)
            : reg.test(valorInputCurrent)
        ) {
          setShowTooltip(true);
          setMessageTooltip(element.message);
          setValidInput(false);
          return;
        }
      }
    }
    setShowTooltip(false);
    setValidInput(true);
    setMessageTooltip("");
  };

  return {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  };
};
export default function Input({
  onChange,
  value,
  label,
  id,
  name,
  type,
  valid,
  rounded,
  tooltip = false,
  delete_button = false,
  disabled = false,
  reset_input = () => {},
  required = false,
  regex = "",
}) {
  const [focused, setFocused] = useState(false);
  const inputError = !valid && value !== "";
  const handleFocus = () => {
    setFocused(!focused);
  };
  const handleChange = (params) => {
    onChange(params);
    handleChangeValue(params);
  };
  const {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  } = useRequired({ required, regex, value });
  useEffect(() => {
    handleFocusRequired(focused);
  }, [focused, handleFocusRequired]);

  return (
    <div
      className={
        "position-relative container-fluid p-0" +
        (disabled ? " pe-none opacity-50" : "")
      }
    >
      <div
        className={`position-absolute pe-none 
        ${rounded ? "input_label_small" : "input_label"}
        ${inputError || !validInput ? `text-danger` : ""}
        ${
          !(focused || value !== "")
            ? "input_label_inside_input"
            : "input_label_outside_input"
        }
      `}
      >
        {label}
      </div>
      <input
        aria-label={name ?? ""}
        id={id}
        data-testid={id}
        name={name}
        type={type}
        onFocus={handleFocus}
        onBlur={handleFocus}
        onChange={handleChange}
        autoComplete="off"
        value={value}
        disabled={disabled}
        style={
          delete_button
            ? { paddingRight: "30px", ...(!valid ? { color: "red" } : {}) }
            : disabled
            ? { overflow: "auto" }
            : {}
        }
        className={`bg-transparent
          ${rounded ? "rounded-pill input_small" : "input"} 
          ${
            inputError || !validInput
              ? `border-danger text-danger`
              : rounded
              ? `border_for_small_inputs`
              : `border-black`
          }
          ${rounded ? "rounded-pill" : ""}
      `}
      />
      {delete_button && value !== "" && (
        <button
          onClick={reset_input}
          className="border border-0 bg-transparent position-absolute z-3 button_input_reset d-flex"
        >
          <img
            src="/images/icons/close_input.png"
            alt="Reset icon"
            width={"16px"}
            height={"16px"}
          />
        </button>
      )}
      {type === "email" && tooltip && (
        <EmailValidation valid={valid} email={value} />
      )}
      {showTooltip && (
        <Tooltip valid={validInput} messageIncorrect={messageTooltip} />
      )}
    </div>
  );
}
