import { useState, useEffect, useRef, useCallback } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import 'dayjs/locale/es-mx';
import { createTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import Tooltip from './Tooltip';
import useIsOpen from './useIsOpen';
import useUpdatePosition from './useUpdatePosition';
import useOutsideClick from './useOutsideClick';
import { createPortal } from 'react-dom';

const CalendarContainer = ({
  targetRef,
  position,
  value,
  newTheme,
  minDate,
  maxDate,
  onChange,
  setIsOpen,
  id,
}) => {
  return createPortal(
    <div
      ref={targetRef}
      style={position}
      className='custom_calendar_contianer'
      data-testid={`${id}_calendar`}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es-mx'}>
        <ThemeProvider theme={newTheme}>
          <DateCalendar
          slotProps={{
            calendarHeader: { "data-testid": "calendar_header" },
            switchViewButton: { "data-testid": "year_button" },
            previousIconButton: { "data-testid": "previous_button_change" },
            nextIconButton: { "data-testid": "next_button_change" },
            yearButton: (props) => {
              return { "data-testid": `year_button_${props?.value}` }
            },
            day: ({ day, ...rest }) => ({
              onClick: () => setIsOpen(false),
              "data-testid": day ? `day_button_${day.format("YYYY_MM_DD")}` : "",
            }),
          }}
            value={value !== '' ? dayjs(value) : null}
            minDate={minDate ?? null}
            maxDate={maxDate ?? dayjs().subtract(1, 'day')}
            onChange={(newValue) => {
              onChange({
                value: newValue.format("YYYY-MM-DD"),
                name: id,
              });
            }}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </div>,
    document.getElementById('root'),
  );
};

const useDatePicker = () => {
  const { isOpen, setIsOpen, handleClick } = useIsOpen();
  const { position, containerRef, updatePosition } = useUpdatePosition();
  const { targetRef } = useOutsideClick({ containerRef, setIsOpen });

  useEffect(() => {
    updatePosition();
  }, [isOpen, updatePosition]);

  const newTheme = createTheme({
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: '#bbdefb',
            borderRadius: '8px',
            border: 'none',
            backgroundColor: '#FFFFFF',
            maxWidth: '200px',
            maxHeight: '172px',
          },
        },
      },
    },
  });
  return {
    newTheme,
    isOpen,
    setIsOpen,
    handleClick,
    position,
    containerRef,
    updatePosition,
    targetRef,
  };
};

const useRequired = ({ valueInput, required, hasFocus }) => {
  const [valid, setValid] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [messageTooltip, setMessageTooltip] = useState('');
  const [interacted, setInteracted] = useState(false);

  useEffect(() => {
    if (hasFocus) {
      setInteracted(true);
    }
  }, [hasFocus]);

  useEffect(() => {
    if (!required || !interacted) return;

    if (valueInput === '') {
      setValid(false);
      setShowTooltip(true);
      setMessageTooltip('Campo vacío requerido');
    } else {
      setValid(true);
      setShowTooltip(false);
      setMessageTooltip('');
    }
  }, [valueInput, interacted, required]);

  return { validRequired: valid, showTooltip, messageTooltip };
};

export default function DatePickerInput({
  onChange,
  value,
  label,
  id,
  valid,
  rounded,
  minDate,
  maxDate = dayjs().subtract(1, 'day'),
  required = false,
}) {
  const {
    newTheme,
    isOpen,
    setIsOpen,
    handleClick,
    position,
    containerRef,
    targetRef,
  } = useDatePicker();

  const { validRequired, showTooltip, messageTooltip } = useRequired({
    valueInput: value,
    required,
    hasFocus: true,
  });
  return (
    <div
      ref={containerRef}
      className='mt-3 container-fluid p-0 position-relative'
    >
      <div
        className={`position-absolute pe-none 
        ${rounded ? 'input_label_small' : 'input_label'}
      ${
        !(value !== '')
          ? 'input_label_inside_input'
          : 'input_label_outside_input'
      }
      `}
      >
        {label}
      </div>
      <button
        data-testid={id}
        onClick={handleClick}
        type='button'
        className={`bg-transparent
      ${rounded ? 'rounded-pill input_small' : 'input'} 
        border_for_small_inputs
        custom_calendar_contianer_button
        d-flex align-items-center gap-1
      ${rounded ? 'rounded-pill' : ''}
  `}
      >
        <span
          style={{
            opacity: !(value !== '') ? '0' : '1',
          }}
        >
          {value !== '' ? dayjs(value).format("DD/MM/YYYY") : 'DD/MM/YYYY'}
        </span>
      </button>
      {isOpen && (
        <CalendarContainer
          {...{
            targetRef,
            position,
            value,
            newTheme,
            minDate,
            maxDate,
            onChange,
            setIsOpen,
            id,
          }}
        />
      )}
      {showTooltip && (
        <Tooltip messageIncorrect={messageTooltip} valid={validRequired} />
      )}
    </div>
  );
}
