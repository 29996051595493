import dayjs from "dayjs";
import { formatToTwoDecimals } from '../../services/data'
import { getImageFromURL } from "../../services/api";

const widthConversionFactor = 0.361;
const heightConversionFactor = 0.392;

const convertWidth = (pixels) => {
  return pixels * widthConversionFactor;
};

const convertHeight = (pixels) => {
  return pixels * heightConversionFactor;
};

export const objetoProvisional = async ({
  node_name,
  start_date,
  end_date,
  node_records,
  transaction_records,
  load_records,
  photo_quality,
  average_time_global,
  average_time_graph,
  total_transactions_graph,
  getImageGraphic,
  indiceCalidadNodo,
  status_node,
  textColor,
  logo,
  logoDimensions
}) => ({
  configuration: {
    compress: true
  },
  texts: {
    titulo: {
      text: "Información dashboard",
      x: convertWidth(30),
      y: convertHeight(75),
      font: {
        fontName: "Mulish",
        fontStyle: "normal",
        fontWeight: 700,
        size: 15,
        textColor,
        lineHeight: 18.83,
      },
    },
    // Nombre del nodo
    titulo2: {
      text: `/ ${node_name}`,
      x: convertWidth(211),
      y: convertHeight(75),
      font: {
        fontName: "Mulish",
        fontStyle: "normal",
        fontWeight: 700,
        size: 15,
        textColor: "#bcbcbc",
        lineHeight: 18.83,
      },
    },
    etiquetaNodo: {
      text: `Nodo ${status_node} - Calidad de Registro ${indiceCalidadNodo}`,
      x: convertWidth(30),
      y: convertHeight(90),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 8,
        textColor: "#2D2D2D",
        lineHeight: 10.04,
      },
    },
    fechas: {
      text: `Desde: ${dayjs(start_date).format(
        "DD/MM/YYYY HH:mm:ss"
      )} hrs | Hasta: ${dayjs(end_date).format(
        "DD/MM/YYYY HH:mm:ss"
      )} hrs`,
      x: convertWidth(392),
      y: convertHeight(75),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 6,
        textColor: "#2D2D2D",
        lineHeight: 10.04,
      },
    },
    fechaConsulta: {
      text: `Fecha y hora del reporte ${dayjs().format(
        "DD/MM/YYYY HH:mm:ss"
      )} (GMT-6)`,
      x: convertWidth(407),
      y: convertHeight(92),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 6,
        textColor: "#2D2D2D",
        lineHeight: 10.04,
      },
    },
    valorRegistrosNodos: {
      text: `${node_records ?? "0"}`,
      x: convertWidth(48.26),
      y: convertHeight(122),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 19.08,
        textColor,
        lineHeight: 23.94,
      },
    },
    registrosNodos: {
      text: `Registros totales en el nodo`,
      x: convertWidth(33.04),
      y: convertHeight(130),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 5.3,
        textColor: "#959595",
        lineHeight: 6.65,
      },
    },
    valorTransaccionesNodo: {
      text: `${transaction_records?? "0"}`,
      x: convertWidth(171.84),
      y: convertHeight(122),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 19.08,
        textColor,
        lineHeight: 23.94,
      },
    },
    transaccionesNodo: {
      text: `Transacciones en el nodo`,
      x: convertWidth(155.77),
      y: convertHeight(130),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 5.3,
        textColor: "#959595",
        lineHeight: 6.65,
      },
    },
    valorNumeroCargasNodo: {
      text: `${load_records?? "0"}`,
      x: convertWidth(55.4),
      y: convertHeight(162),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 19.08,
        textColor,
        lineHeight: 23.94,
      },
    },
    numeroCargasNodo: {
      text: `Cargas en el nodo`,
      x: convertWidth(31.6),
      y: convertHeight(173),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 5.3,
        textColor: "#959595",
        lineHeight: 6.65,
      },
    },
    etiquetaPromedioTiempoRespuesta: {
      text: `Promedio tiempo de respuesta`,
      x: convertWidth(157.67),
      y: convertHeight(173),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 5.3,
        textColor: "#959595",
        lineHeight: 6.65,
      },
    },
    etiquetaCaliodadFoto: {
      text: `Promedio índice calidad de la fotografía`,
      x: convertWidth(278.97),
      y: convertHeight(173),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 5.3,
        textColor: "#959595",
        lineHeight: 6.65,
      },
    },
    valorNumeroCalidadFoto: {
      text: `${formatToTwoDecimals(photo_quality) ?? "0"}`,
      x: convertWidth(297.52),
      y: convertHeight(162),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 19.08,
        textColor,
        lineHeight: 23.94,
      },
    },
    valorPromedioTiempoRespuesta: {
      text: `${average_time_global ?? "0"}`,
      x: convertWidth(175.74),
      y: convertHeight(162),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 19.08,
        textColor,
        lineHeight: 23.94,
      },
    },
    unidadesPromedioTiempoRespuesta: {
      text: "seg",
      x: convertWidth(241),
      y: convertHeight(162),
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 8,
        textColor,
        lineHeight: 23.94,
      },
    },
    //Contenido Grafica 1
    etiquetaTotalTransacciones: {
      text: `Total de transacciones en el nodo:`,
      x: 15,
      y: 80,
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 8.75,
        textColor,
        lineHeight: 10.98,
      },
    },
    valorTotalTransacciones: {
      text: `${total_transactions_graph?? "0"}`,
      x: 66,
      y: 80,
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 8.75,
        textColor: "#2D2D2D",
        lineHeight: 10.98,
      },
    },
    /*maxTransacciones: {
      text: "Máx. transacciones",
      x: 165,
      y: 83,
      font: {
        fontName: "Mulish",
        fontStyle: "normal",
        size: 4.73,
        textColor: "#2D2D2D",
        lineHeight: 5.49,
      },
    },
    minTransacciones: {
      text: "Mín. transacciones",
      x: 184,
      y: 83,
      font: {
        fontName: "Mulish",
        fontStyle: "normal",
        size: 4.73,
        textColor: "#2D2D2D",
        lineHeight: 5.49,
      },
    },*/
    //Contenido Grafica 2
    etiquetaTiempoRespuesta: {
      text: `Promedio tiempo de respuesta del nodo: `,
      x: 15,
      y: 148,
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 8.75,
        textColor,
        lineHeight: 10.98,
      },
    },
    valorTiempoRespuesta: {
      text: `${average_time_graph ?? "0"} seg`,
      x: 76,
      y: 148,
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 8.75,
        textColor: "#2D2D2D",
        lineHeight: 10.98,
      },
    },
    // Gráfica 3 Contenido
    etiquetaResultScore: {
      text: `Resultados de la validación`,
      x: 15,
      y: 208,
      font: {
        fontName: "Mulish",
        fontStyle: "bold",
        size: 8.75,
        textColor,
        lineHeight: 10.98,
      },
    },
    noExistResultScore: {
      text: "No Iniciada",
      x: 117,
      y: 213,
      font: {
        fontName: "Mulish",
        fontStyle: "normal",
        size: 4.73,
        textColor: "#2D2D2D",
        lineHeight: 5.49,
      },
    },
    noCoincidResultScore: {
      text: "No Exitosa",
      x: 130,
      y: 213,
      font: {
        fontName: "Mulish",
        fontStyle: "normal",
        size: 4.73,
        textColor: "#2D2D2D",
        lineHeight: 5.49,
      },
    },
    coincidValIDScore: {
      text: "Exitosa",
      x: 151,
      y: 213,
      font: {
        fontName: "Mulish",
        fontStyle: "normal",
        size: 4.73,
        textColor: "#2D2D2D",
        lineHeight: 5.49,
      },
    },
    coincidNa: {
      text: "Sin Registro",
      x: 168,
      y: 213,
      font: {
        fontName: "Mulish",
        fontStyle: "normal",
        size: 4.73,
        textColor: "#2D2D2D",
        lineHeight: 5.49,
      },
      
    }
  },
  rects: {
    //Registros totales color vino #6d1644
    rect1: {
      x: convertWidth(24),
      y: convertHeight(100),
      w: convertWidth(115.59),
      h: convertHeight(35.68),
      styles: {
        fillColor: "#FFFFFF",
        strokeColor: "#efeded",
      },
    },
    //Promedio de tiempo de respuesta color morado #8f5dea
    rect2: {
      x: convertWidth(146.73),
      y: convertHeight(141),
      w: convertWidth(115.59),
      h: convertHeight(35.68),
      styles: {
        fillColor: "#FFFFFF",
        strokeColor: "#efeded",
      },
    },
    //Promedio índice de calidad foto color azul #008bff
    rect3: {
      x: convertWidth(270.41),
      y: convertHeight(141),
      w: convertWidth(115.59),
      h: convertHeight(35.68),
      styles: {
        fillColor: "#FFFFFF",
        strokeColor: "#efeded",
      },
    },
    // Total de transacciones en el nodo color verde #7ed327
    rect4: {
      x: convertWidth(24),
      y: convertHeight(185.75),
      w: convertWidth(546),
      h: convertHeight(167.71),
      styles: {
        fillColor: "#FFFFFF",
        strokeColor: "#efeded",
      },
    },
    // Promedio tiempo de respuesta del nodo color rojo #ff0000
    rect5: {
      x: convertWidth(24),
      y: convertHeight(358.42),
      w: convertWidth(545.13),
      h: convertHeight(146.58),
      styles: {
        fillColor: "#FFFFFF",
        strokeColor: "#efeded",
      },
    },
    //Promedio de ValID color amarillo #f7df1e
    rect6: {
      x: convertWidth(24),
      y: convertHeight(511),
      w: convertWidth(546.86),
      h: convertHeight(188.37),
      styles: {
        fillColor: "#FFFFFF",
        strokeColor: "#efeded",
      },
    },
    // Cargas del nodo naranja #f78500
    rect7: {
      x: convertWidth(24),
      y: convertHeight(141),
      w: convertWidth(115.59),
      h: convertHeight(35.68),
      styles: {
        fillColor: "#FFFFFF",
        strokeColor: "#efeded",
      },
    },
    // Transacciones en el nodo cafe #70482f
    rect8: {
      x: convertWidth(147.2),
      y: convertHeight(100),
      w: convertWidth(115.59),
      h: convertHeight(35.68),
      styles: {
        fillColor: "#FFFFFF",
        strokeColor: "#efeded",
      },
    },
  },
  images: {
    image1: {
      image:  `${logo}`,
      format: "",
      x: logoDimensions?.x,
      y: logoDimensions?.y,
      width: logoDimensions?.w,
      height: logoDimensions?.h,
    },
    image2: {
      image: "/images/dashboard/dashboard_db.png",
      format: "",
      x: convertWidth(33.04),
      y: convertHeight(111),
      width: convertWidth(11.26),
      height: convertHeight(11.32),
    },
    image3: {
      image: "/images/dashboard/dashboard_transactions.png",
      format: "",
      x: convertWidth(155.29),
      y: convertHeight(112),
      width: convertWidth(13.32),
      height: convertHeight(8.35),
    },
    image4: {
      image: "/images/dashboard/dashboard_cargas.png",
      format: "",
      x: convertWidth(33.51),
      y: convertHeight(151.18),
      width: convertWidth(12.37),
      height: convertHeight(10.4),
    },
    image40: {
      image: "/images/dashboard/clock.png",
      format: "",
      x: convertWidth(157.67),
      y: convertHeight(149.18),
      width: convertWidth(15.32),
      height: convertHeight(13.82),
    },
    image41: {
      image: "/images/dashboard/image.png",
      format: "",
      x: convertWidth(278.97),
      y: convertHeight(151.18),
      width: convertWidth(13.32),
      height: convertHeight(12.32),
    },
    /*image5: {
      image: "/images/dashboard/dashboard_dot_green.png",
      format: "",
      x: 163,
      y: 82,
      width: 1,
      height: 1,
    },
    image6: {
      image: "/images/dashboard/dashboard_dot_red.png",
      format: "",
      x: 182,
      y: 82,
      width: 1,
      height: 1,
    },*/
    image7: {
      image: "/images/dashboard/dashboard_dot_gray.png",
      format: "",
      x: 115,
      y: 212,
      width: 1,
      height: 1,
    },
    image8: {
      image: "/images/dashboard/dashboard_dot_blue.png",
      format: "",
      x: 128,
      y: 212,
      width: 1,
      height: 1,
    },
    image9: {
      image: "/images/dashboard/dashboard_dot_light_purple.png",
      format: "",
      x: 148,
      y: 212,
      width: 1,
      height: 1,
    },
    image13: {
      image: "/images/dashboard/dot_na.png",
      format: "",
      x: 165,
      y: 211.7,
      width: 1.7,
      height: 1.7,
    },
    image10: {
      image: await getImageGraphic("historicoNumeroTotalUnico"),
      format: "",
      x: 9,
      y: 86,
      width: 192,
      height: 52,
    },
    image11: {
      image: await getImageGraphic("tiempoPromedio"),
      format: "",
      x: 10,
      y: 156,
      width: 191,
      height: 40,
    },
    image12: {
      image: await getImageGraphic("resultValidNode"),
      format: "",
      x:10,
      y: 220,
      width: 192,
      height: 53,
    },
  }
})