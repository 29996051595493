import React from 'react'
import { ReactComponent as ExportIcon } from "../../assets/images/export.svg"

export default function ExportButton(props) {
  return (
    <button
        type='button'
        data-testid={"export_button"}
        className='export_button d-flex gap-1 justify-content-center align-items-center'
        {...props}
      >
        Exportar
        <ExportIcon />
      </button>
  )
}
