import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { createPortal } from "react-dom";

const Modal = forwardRef(({ children }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(( prevState ) => !prevState);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
    document.body.style.overflow = "auto";
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      handleOpenModal
    };
  }, []);

  return createPortal(
    <>
      {isOpen ? 
        <div className={`
          modal_container 
          position-fixed 
          w-100 
          top-0 
          start-0 
          overflow-x-hidden
          overflow-y-auto
          min-vh-100
        `}>{children}</div> : null}
    </>,
    document.getElementById("main_html_body")
  );
});

export default Modal;
